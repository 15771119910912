<template>
  <div>
    <!-- <div style="margin-top:10px;width:700px;padding:20px;">
      这个示例中，调用setJsonData时传递的是一个典型的有层级结构的数据，图谱会自动识别（通过children属性识别子节点），再将其做扁平化处理。这样做仅仅是为了方便展示一些树状图谱。
      <br />
      直接使用tree数据结构有明显的确缺陷：只能设置全局的线条默认样式，无法对具体的link设置属性，不能精细化的定义线条的样式。
    </div> -->
    <div
      v-loading="g_loading"
      style="width: calc(100vw - 2px); height: calc(100vh - 10px)"
    >
      <RelationGraph
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="onNodeClick"
      >
        <div
          slot="node"
          slot-scope="{ node }"
          style="display: flex; align-items: center"
        >
          <span v-if="isShowCount">{{ node.data.text }}</span>
          <span v-else>{{ node.data.word }}</span>
          <i
            v-if="node.data.icon"
            style="font-size: 24px; margin-left: 4px"
            :class="node.data.icon"
          />
        </div>
      </RelationGraph>
    </div>
  </div>
</template>

<script>
import RelationGraph from 'relation-graph'
import { formatRegex } from '@/utils/helper'
import { getLoginConfig } from '@/api/module'
export default {
  name: 'SeeksRelationGraph',
  data() {
    return {
      timer: 0,
      g_loading: false,
      graph_json_data: {
        rootId: '',
        nodes: [],
        links: []
      },
      graphOptions: {
        debug: false,
        backgrounImage: 'https://web-app.dtbird.cn/web_icon/shuiyin.png',
        backgrounImageNoRepeat: false,
        layout: {
          label: '中心',
          layoutName: 'tree',
          layoutClassName: 'seeks-layout-center',
          defaultJunctionPoint: 'border',
          defaultNodeShape: 0,
          defaultLineShape: 1,
          from: 'left',
          // 通过这4个属性来调整 tree-层级距离&节点距离
          min_per_width: '320',
          max_per_width: '500',
          min_per_height: '40',
          max_per_height: '40'
          // levelDistance: '400' // 如果此选项有值，则优先级高于上面那4个选项
        },
        defaultNodeWidth: 200,
        defaultNodeHeight: 20,
        defaultExpandHolderPosition: 'right',
        defaultNodeShape: 1,
        disableDragNode: true,
        disableZoom: false,
        defaultLineShape: 4,
        defaultJunctionPoint: 'lr',
        defaultNodeBorderWidth: 0,
        defaultNodeBorderColor: 'none',
        checkedLineColor: 'none',
        defaultLineColor: 'rgba(0, 186, 189, 1)',
        defaultNodeColor: 'rgba(0, 206, 209, 1)'
      },
      isShowCount: true
    }
  },
  props: {
    dataJson: {
      type: Array,
      default: () => []
    },
    treeDataText: {
      type: String,
      default: ''
    },
    modeIndex: {
      type: Number,
      default: 0
    }
  },
  watch: {
    dataJson: {
      handler(list) {
        if (list.length) {
          console.log(list, 'list123')
          this.handleJsonData()
        }
      }
    },

    modeIndex(val) {
      if (val == 0) {
        this.$nextTick(() => {
          this.$refs.seeksRelationGraph.refresh()
          this.$refs.seeksRelationGraph.zoom(-20)
        })
      }
    },

    '$store.state.settings.changeColor': {
      handler(val) {
        if (val == 0) {
          this.graphOptions.defaultLineColor = 'rgba(0, 186, 189, 1)'
          this.graphOptions.defaultNodeColor = 'rgba(0, 186, 189, 1)'
        } else {
          this.graphOptions.defaultLineColor = '#5367cb'
          this.graphOptions.defaultNodeColor = '#021EAA'
        }
        this.$refs.seeksRelationGraph.setOptions(this.graphOptions, dom => {
          dom.refresh()
          dom.zoom(-20)
        })
      }
    },

    '$store.state.settings.changeCount': {
      handler(val) {
        this.isShowCount = val
        this.$refs.seeksRelationGraph.refresh()
      }
    }
  },

  components: {
    RelationGraph
  },

  mounted() {
    // 选择需要观察变动的节点
    const targetNode = this.$refs.seeksRelationGraph.$el
    // 观察器的配置（需要观察什么变动）
    const config = { attributes: true, childList: true, subtree: true }
    // 当观察到变动时执行的回调函数
    const callback = function (mutationsList, observer) {
      // Use traditional 'for loops' for IE 11
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // console.log('A child node has been added or removed.')
        } else if (mutation.type === 'attributes') {
          const child = document.querySelector('.seeks-layout-center')
          // if (!child.style.backgroundImage) {
          //   //防止图片盗用
          //   window.location.reload()
          // }
        }
      }
    }
    // 创建一个观察器实例并传入回调函数
    const observer = new MutationObserver(callback)
    // 以上述配置开始观察目标节点
    observer.observe(targetNode, config)
  },
  methods: {
    async handleTheme() {
      const { data } = await getLoginConfig()
      const { primaryColor } = data
      this.graphOptions.defaultLineColor = primaryColor
      this.graphOptions.defaultNodeColor = primaryColor
      this.$refs.seeksRelationGraph.setOptions(this.graphOptions, dom => {
        dom.refresh()
      })
    },

    handleJsonData() {
      this.handleTreeData(this.dataJson)
      this.graph_json_data = {
        rootId: this.treeDataText,
        nodes: this.dataJson,
        links: []
      }
      this.setGraphData()
    },
    onNodeClick(e) {
      if (e.data.total > 0) {
        this.$emit('clickNode', e.data)
      }
    },
    handleTreeData(list) {
      list.forEach(item => {
        item.text = `${item.name}(${item.tagCnt})`
        item.id = `${item.id}`
        item.data = {
          word: item.name,
          text: `${item.name}(${item.tagCnt})`,
          id: item.id,
          tagName: item.name,
          total: item.tagCnt,
          home: item.homeCnt,
          abroad: item.abroadCnt,
          hot: formatRegex(item.hot),
          icon: item.hitCompany ? 'el-icon-s-flag' : ''
        }
        if (item.children) {
          this.handleTreeData(item.children)
        }
      })
    },

    onCanvasDragEnd() {
      console.log('end')
    },
    setGraphData() {
      this.g_loading = true
      // 使用要点：通过节点属性expandHolderPosition: 'right' 和 expanded: false 可以让节点在没有子节点的情况下展示一个"展开"按钮
      // 通过onNodeExpand事件监听节点，在被展开的时候有选择的去从后台获取数据，如果已经从后台加载过数据，则让当前图谱根据当前的节点重新布局
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout(() => {
        this.g_loading = false

        this.$refs.seeksRelationGraph.setJsonData(this.graph_json_data, dom => {
          const list = dom.getNodes()
          list.forEach(e => {
            if (e.data.total == 0) {
              e.color = '#ebeef5'
              e.borderColor = '#ebeef5'
              e.fontColor = '#c0c4cc'
            }
            e.styleClass = 'node-class'
            // e.width=200
          })
        })
        this.handleTheme()
      }, 1000)
    }
  }
}
</script>
<style lang="scss">
@import '~@/styles/variables.scss';

.node-class {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  line-height: 20px !important;
  font-size: 12px !important;
}
.rel-node-shape-1 {
  box-shadow: none !important;
  border-radius: 2px !important;
}
.rel-map {
  cursor: pointer !important;
}
.c-mini-toolbar {
  top: 0 !important;
  right: -10px !important;
  bottom: auto !important;
  transform: scale(1) !important;
}
.rel-node-hover {
  position: relative;
}
</style>
