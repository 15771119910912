<template>
  <div>
    <el-form
      ref="form"
      :model="form"
      label-width="auto"
      :inline="true"
      size="mini"
    >
      <el-form-item
        v-for="(item, index) in formOpt"
        :key="index"
        :label="item.name"
      >
        <template v-if="item.type == 'input'">
          <el-input v-model="form[item.field]"></el-input>
        </template>

        <template v-if="item.type == 'select'">
          <el-select v-model="form[item.field]" placeholder="请选择">
            <el-option
              v-for="item in handleOptions(item.filterOptionList)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>

        <template v-if="item.type == 'date'">
          <el-date-picker
            v-model="form[item.field]"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </template>

        <template v-if="item.type == 'qz-date'">
          <el-date-picker
            v-model="form[item.field]"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </template>
      </el-form-item>
      <el-form-item>
        <el-button type="plain" @click="onReset">重置</el-button>
        <el-button type="primary" @click="handleInquire">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'PeForm',

  props: {
    formOpt: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      form: {}
    }
  },

  methods: {
    handleOptions(list) {
      return list.map(item => ({ label: item.name, value: item.value }))
    },

    onReset() {},

    handleInquire() {
      console.log(this.form)
    }
  },

  components: {}
}
</script>
<style lang="scss" scoped></style>
