/*
 * @Author: Shy07
 * @Date: 2019-06-27 10:34:21
 * @Last Modified by: Shy07
 * @Last Modified time: 2019-06-27 13:54:08
 */

const apiData = (url, method) => ({
  url: `${process.env.VUE_APP_BASE_API}${url}`,
  method
})

export default {
  CALL_UP: apiData('/api/waihu/start', 'post'),
  HANG_UP: apiData('/api/waihu/end', 'post'),
  FETCH_TOKEN: apiData('/api/auth/login', 'post'),
  FETCH_TOKEN_WITH_MOBILE: apiData('/api/auth/regist/account', 'post'),
  SEND_PC_CODE: apiData('/api/auth/send/for/login/pc', 'post'),
  CODE_PC_TOKEN: apiData('/api/auth/sms/login', 'post'),
  GETBREEDHEIOPTION: apiData('/api/contact/pool/filter', 'get'),

  REFRESH_TOKEN: apiData('/api/auth/refresh', 'post'),
  // 线索公海-是否显示云服务商变更
  SET_GONGYINGSHANG: apiData('/api/package/btn/idc', 'get'),
  // 线索公海-是否显示网站可访问
  SET_WEBSITE: apiData('/api/package/btn/web', 'get'),
  // 跟进记录-系统-客户
  RECORD_CUSTOMER: apiData('/api/customer/list/records', 'get'),
  // 跟进记录-全部
  RECORD_All: apiData('/api/customer/list/records/all', 'get'),
  // 联系人删除
  COUSTOMER_DEL: apiData('/api/customer/contact/del', 'post'),
  // 联系人修改
  COUSTOMER_EDIT: apiData('/api/customer/contact/update', 'post'),
  // 联系人列表
  COUSTOMER_LIST: apiData('/api/customer/contact/list', 'get'),
  // 联系人新增
  COUSTOMER_ADD: apiData('/api/customer/contact/add', 'post'),

  // 客户名称列表
  CONTRACT_COMPANYLIST: apiData('/api/customer/contract/list/customer', 'get'),

  // 客户画像新增
  USERPROFILE_ADD: apiData('/api/customer/profile', 'post'),
  // 客户画像修改
  USERPROFILE_EDIT: apiData('/api/customer/profile', 'put'),
  // 客户画像删除
  USERPROFILE_DEL: apiData('/api/customer/profile', 'delete'),
  // 客户画像查询
  USERPROFILE_SEARCH: apiData('/api/customer/profile', 'get'),
  // 客户画像词云
  USERPROFILE_WORD: apiData('/api/customer/profile/wordcloud', 'post'),

  // 合同修改
  CONTRACT_EDIT: apiData('/api/customer/contract/info/edit', 'post'),
  // 合同负责人
  CONTRACT_LEADERS: apiData('/api/customer/contract/leaders', 'get'),
  // 合同删除
  CONTRACT_DEL: apiData('/api/customer/contract/info', 'delete'),
  // 合同新增
  CONTRACT_ADD: apiData('/api/customer/contract/info', 'post'),
  // 合同高级查询
  CONTRACT_SEARCH: apiData('/api/customer/contract/list/select', 'get'),
  // 合同高级查询筛选条件获取
  CONTRACT_FILTER: apiData('/api/customer/contract/filter', 'get'),
  // 查重
  SEARCH_REPEAT: apiData('/api/collection/check', 'get'),
  // 高级查找请求
  HEGHT_GET: apiData('/api/es/company/find', 'JsonPost'),
  // 高级查找，筛选条件
  HEIGHT_SEACH: apiData('/api/es/company/find/filter', 'get'),
  GET_KPOPTION: apiData('/api/module/social/filter/list', 'get'),
  POSTSOCIALKP: apiData('/api/module/social/filter/save', 'post'),
  GETSOCIALKP: apiData('/api/module/social/filter/history', 'get'),
  // 退出登录
  USER_LOGOUT: apiData('/api/user/logout', 'post'),
  // 个人信息
  USER_CENTER: apiData('/api/user/center', 'get'),
  // 修改手机号
  CHANGE_MOBILE: apiData('/api/user/mobile', 'put'),
  // 修改密码
  CHANGE_PASSWORD: apiData('/api/user/password', 'put'),
  // 修改企业信息
  CHANGECOM_INFO: apiData('/api/enterprise/info', 'put'),
  // 获取手机验证码
  SMS_SEND: apiData('/api/auth/sms/send', 'post'),
  // 校验验证码
  CHECK_CODE: apiData('/api/auth/sms/check', 'post'),
  // 定制线索包弹框
  // 推荐线索包  // 刷新线索包
  GIVE_PACKAGE: apiData('/api/package', 'get'),
  // 购买线索
  // 支付
  // 获取绑定手机号哦
  GET_BINDPHONE: apiData('/api/collection/call/mobile', 'get'),
  // 获取版本定价
  GET_PVERSION: apiData('/api/pversion', 'get'),

  // 定制线索包
  GET_CUSMADE: apiData('/api/package/custom/made', 'post'),

  // 计算价格版本价格
  GET_PREPRICE: apiData('/api/setMeal/calculation', 'get'),

  // 计算子账户，订阅卡，线索价格
  GET_PREPRICEOTHER: apiData('/api/setMeal/calculation/other', 'get'),

  // 生成订阅卡订单
  POST_ORDERCARD: apiData('/api/order/card', 'post'),
  // 绑定手机号
  GET_PHONEBIND: apiData('/api/call/bind', 'post'),

  // 判断是否订阅线索包
  GET_PACKHADBOOKING: apiData('/api/package/hadBooking', 'get'),
  // 拨打电话
  GET_PHONECALL: apiData('/api/collection/call', 'post'),

  // 外呼结果回调
  GET_CALLBACK: apiData('/api/auth/call/callBack', 'post'),

  // 修改联系电话状态/api/collection/mobile/status
  GET_CHANGEPHONESTA: apiData('/api/collection/call/status', 'put'),

  // 收藏列表查询
  GET_SEARCHLIST: apiData('/api/collection/condition', 'get'),

  // 支付相关
  // 购买返回订单号价格
  GET_BUTORDER: apiData('/api/order', 'post'),

  // 支付宝支付
  GET_ZHBPAY: apiData('/api/pay', 'get'),

  // baoyuApi adress
  // 获客方式
  CUSTOMER_WAY: apiData('/api/customerWay/list', 'get'),
  // 获取验证码
  GET_CODE: apiData('/api/auth/sms/send', 'post'),
  // 获取高级筛选缓存
  GETSEARCHPLUS: apiData('/api/filter/cache', 'get'),
  // 缓存高级筛选
  SETSEARCHPLUS: apiData('/api/filter/cache', 'post'),
  // 获取地址字典
  GET_ADDRESS: apiData('/api/area/list', 'get'),
  // 获取地址字典2
  GET_ADDRESS2: apiData('/api/customer/list/filter/city', 'get'),
  // 获取类别字典
  GET_MODE: apiData('/api/ddic', 'get'),
  // 手机号验证码
  USER_PHONE_CODE: apiData('/api/auth/sms/check', 'post'),
  // 修改密码
  USER_PWD_UPDATE: apiData('/api/auth/resetPassword', 'post'),
  // 检测用户名是否存在
  USER_CHECK_NAME: apiData('/api/auth/regist/checkAccount', 'post'),
  // 注册
  USER_REGISTER_BASE: apiData('/api/auth/regist/account', 'post'),
  // 企业信息
  USER_REGISTER_BUSINESS: apiData('/api/enterprise/info', 'post'),
  // 增加企业画像
  USER_REGISTER_COUSTOMER: apiData('/api/package/custom/make', 'post'),
  // 注册企业画像
  USER_COMPANY_COUSTOMER: apiData('/api/enterprise/portrait', 'post'),
  // 系统配置
  GET_SYSTEM_CONFIG: apiData('/api/system/config', 'get'),
  // 线索包分类
  PACKAGE_MARK_SORT: apiData('/api/package/category', 'get'),
  // 线索包市场列表
  PACKAGE_MARK_LIST: apiData('/api/package', 'get'),
  // 线索包订阅
  PACKAGE_MARK_SUBSCRIBE: apiData('/api/package/booking', 'post'),
  // 线索包取消订阅
  PACKAGE_MARK_UNSUBSCRIBE: apiData('/api/package/unbooking', 'post'),
  // huyangmingApi adress
  // 线索包
  PACKAGE_LIST: apiData('/api/package/mine', 'get'),
  // 线索包详情(条件)
  PACKAGE_DETAIL_CONDITION: apiData('/api/package/codition', 'get'),
  // 自动过滤数据
  AutoFilterData: apiData('/api/collection/filter/export/cnt', 'post'),
  // 线索包详情
  PACKAGE_DETAIL: apiData('/api/package/', 'get'),
  // 线索包高级查询筛选条件
  PACKAGE_FILTER: apiData('/api/package/filter', 'get'),
  // 线索收藏
  COLLECTION_ADD: apiData('/api/collection/add', 'post'),
  // 取消线索收藏
  COLLECTION_REMOVE: apiData('/api/collection/remove', 'post'),
  // 线索详情
  COMPANYUUID: apiData('/api/package/companyUuid', 'get'),
  // 收藏列表
  COLLECTION_LIST: apiData('/api/collection/list', 'get'),
  // 领取进度条
  collectionProcess: apiData('/api/collection/consumption/percent', 'get'),
  // 拨打电话
  COLLECTION_CALL: apiData('/api/collection/call', 'post'),
  // 获取主拨电话
  GET_CALL: apiData('/api/collection/call/mobile', 'get'),
  // 对线索评价
  COLLECTION_COMMENT: apiData('/api/collection/comment', 'post'),
  // 后去状态
  GET_CALLSTATUS: apiData('/api/collection/call/status', 'get'),
  // 错误线索反馈
  ERROR_COMMIT: apiData('/api/package/feedback', 'post'),

  // 根据三级code获取地址PinGet
  GET_THREEAREA: apiData('/api/area', 'PinGet'),

  // 调取支付结果
  GET_PAYRESULT: apiData('/api/returnPay', 'get'),

  // 企业画像行业字典
  GET_DDICINDUSTRY: apiData('/api/ddic/industry', 'get'),

  // 删除客户画像
  DELETE_CUSPIC: apiData('/api/enterprise/portrait', 'PinDel'),

  // 个人中心 基本账户信息
  GET_ACCOUNTINFO: apiData('/api/user/center/account', 'get'),

  // 个人中心 企业信息
  GET_COMPANYINFO: apiData('/api/user/center/enterprise', 'get'),

  // 个人中心 企业画像
  GET_COMPANYPIC: apiData('/api/user/center/portraits', 'get'),

  // 个人中心 已购订单
  GET_HADORDER: apiData('/api/user/center/payOrders', 'get'),

  // 查询线索包高级查询状态
  GET_PACKAGETAG: apiData('/api/package/ct', 'get'),

  // 查询线索包高级查询状态
  DEL_PACKAGETAG: apiData('/api/package/ct', 'delete'),

  // 查询线索包高级查询状态
  POST_PACKAGETAG: apiData('/api/package/ct', 'post'),

  // --------------------------------------------------------------------2.2.0版本接口
  // 查询子账号列表
  GET_SUBACCOUNT: apiData('/api/childUser/account/list', 'get'),

  // 设置子账号权限
  PUT_CHILDUSERCONTROL: apiData('/api/childUser/control', 'put'),

  // 专拍线索
  GET_TRANSINFOTOUSER: apiData('/api/transferred/sendClues', 'post'),

  // 获取子账号详情
  GET_CHILDUSERDETAIL: apiData('/api/childUser/account', 'get'),

  // 子账户列表
  GET_TRANSUSERLIST: apiData('/api/transferred/control/users', 'get'),

  // 添加子账号
  POST_CHILD_ACC: apiData('/api/childUser/account', 'post'),

  // 查询剩余子账号数量
  GET_CHILD_NUM: apiData('/api/childUser/account/number', 'get'),

  // 修改子账号
  PUT_CHILD_ACC: apiData('/api/childUser/account', 'put'),

  // 删除子账户
  DEL_CHILD_ACC: apiData('/api/childUser/account', 'PinDel'),

  // 个人中心-账户余额
  GET_ACCEARN: apiData('/api/user/center/remain', 'get'),
  SET_CUSTOMERCONFIG: apiData('/api/enterprise/cus', 'post'),
  // 获取可用线索数量
  GET_KEYNUM: apiData('/api/package/companyNum', 'get'),

  // --------------------------------------------------------------支付相关
  // 批量删除子账户
  DEL_SUBACCRE: apiData('/api/childUser/account/batchRemove', 'delet'),

  // 消耗线索
  POST_CONSUM: apiData('/api/package/consumption', 'post'),

  // 订单列表
  GET_ORDERLIST: apiData('/api/order/list', 'get'),

  // 获取基础套餐列表
  GET_BASEMEALLIST: apiData('/api/setMeal/base/list', 'get'),

  // -----------------------------------------------------------------------支付新接口
  // 生成购买子账户订单
  POST_SUBORDER: apiData('/api/account/order', 'post'),

  // 生成充值话费订单
  POST_CALLCHARGE: apiData('/api/callcharge/order', 'post'),

  // 生成基础套餐订单
  POST_BASICORDER: apiData('/api/basicpackage/order', 'post'),

  // 生成购买线索订单
  POST_CLUBORDER: apiData('/api/clue/order', 'post'),

  // 拨打电话
  POST_APICALL: apiData('/api/collection/call', 'post'),

  // 轮询查询电话状态
  GET_COLLCALLSTATUS: apiData('/api/collection/callDetail', 'PingGet'),

  // 上传文件
  GET_FILEUPLOAD: apiData('/api/collection/import', 'loadPost'),

  // 客户详情模块
  GET_COMDETAILMODEL: apiData('/api/package/company', 'get'),

  // app详情模块
  GET_APP_DETAIL: apiData('/api/package/appinfo', 'get'),

  // 标记下拉框
  GET_DETAILASIGNOPTION: apiData('/api/phone/option', 'get'),

  // 标记下拉框
  POST_DETAILPHONEASIGN: apiData('/api/phone', 'post'),

  // 软电话记录
  POSTWEBRTC: apiData('/api/waihu/add', 'post'),
  // 导出文件
  POST_EXPORT: apiData('/api/collection/export', 'post'),

  // 找回用户名
  GET_USERNAME: apiData('/api/auth/findAccount', 'noHGet'),

  // 申请测试
  GET_USERAPPLY: apiData('/api/user/center/beta', 'post'),

  // 申请测试状态
  GET_TESTSTATUS: apiData('/api/user/center/beta', 'get'),

  // 一键消耗
  POST_CONSUMPTION: apiData('/api/collection/consumption', 'post'),

  // 标签列表
  GET_TagsList: apiData('/api/customer/tags/enterprise', 'get'),

  // 标签新增
  GET_TagsAdd: apiData('/api/customer/tags/info', 'post'),

  // 标签删除
  GET_TagsDel: apiData('/api/customer/tags/info', 'PinDel'),

  // 标签修改
  GET_TagsEdit: apiData('/api/customer/tags/info', 'put'),

  // 线索新增
  ADD_CLUE: apiData('/api/collection/info', 'post'),

  // 线索编辑
  EDIT_CLUE: apiData('/api/collection/modify', 'post'),

  // 线索编辑
  SET_COSTORMER: apiData('/api/collection/transform2Customer', 'post'),

  // es全局搜索
  GET_ESSEARCH: apiData('/api/es/company/search', 'get'),

  // es行业
  GET_ESINDUSTRY: apiData('/api/es/company/industry', 'get'),

  // 融资轮次
  GET_ESVC: apiData('/api/es/company/vc', 'get'),

  // 私有线索包
  GET_PACKPRIVATE: apiData('/api/package/user/private', 'get'),

  // 撤销定制线索包
  DEL_CANCELPRISE: apiData('/api/package/custom/cancel', 'delete'),

  // 触达列表
  GET_TOUCHPACK: apiData('/api/package/touch', 'get'),

  // 线索公海页码
  GET_CODITIONPAGE: apiData('/api/package/codition/page', 'get'),

  // 公司产品总览
  GET_COMCUSPROVIEW: apiData('/api/package/appPreview', 'get'),

  // 全局搜索推荐公司/es/company/recommend
  GET_ESRECOMMEND: apiData('/api/es/company/recommend', 'get'),

  // 是否有推荐公司
  GET_ESISRECOMM: apiData('/api/es/company/isRecommend', 'get'),
  // 检验手机号是否注册
  GET_AUTHCHECKMOBILE: apiData('/api/auth/checkMobile', 'get'),
  // 获取用户基本信息
  GET_AUTHUSERMEAL: apiData('/api/user/meal', 'get'),
  // 获取线索包大区条数/api/package/booking/companyNum
  GET_BOOKINGCOMNUM: apiData('/api/package/booking/companyNum', 'get'),
  // 订阅更多线索包数据/api/package/booking/more
  GET_PACKBOOKINGMORE: apiData('/api/package/booking/more', 'post'),
  // 收藏夹列表
  GET_FAVORITESLIST: apiData('/api/collection/favorites/list', 'get'),
  // 添加用户收藏家
  GET_FAVORITESADD: apiData('/api/collection/favorites/add', 'post'),
  // 更新用户收藏夹
  POST_FAVORITESUPDATE: apiData('/api/collection/favorites/update', 'post'),
  // 删除用户收藏夹
  DEL_FAVORITESDEL: apiData('/api/collection/favorites/delect', 'delete'),
  // 移动分组
  POST_MOVEFAVOTITESGROUP: apiData('/api/collection/favorites/mobile', 'post'),
  // 落地页列表
  GET_MARKETINGLANDLIST: apiData('/api/marketing/landPage/list', 'get'),
  // 落地页预览
  GET_PREVIEW: apiData('/api/marketing/landPage/preview', 'get'),
  //是否全公司可见
  POST_ENABLEVIEW: apiData('/api/marketing/landPage/enableView', 'post'),
  // 落地页图片
  POST_MARKETUPLOAD: apiData('/api/marketing/landPage/upload/file', 'loadPost'),
  // 落地页获取ststoken
  GET_STSTOKEN: apiData('/api/oss/sts/token', 'get'),
  // 增加落地页
  POST_MARKINGLANDPAGE: apiData('/api/marketing/landPage/page', 'loadPost'),
  // 发布落地一
  POST_MARKETINGPUBLISH: apiData('/api/marketing/landPage/publish', 'loadPost'),
  // 删除落地页
  DEL_MARKETLANDPAGE: apiData('/api/marketing/landPage/page', 'delete'),
  // 获取详情
  GETMARKETLANDPAGEDETAIL: apiData('/api/marketing/landPage/page', 'PingGet'),
  // 修改落地页
  GETMARKETLANPAGEUPDATE: apiData('/api/marketing/landPage/page', 'put'),
  // 表单重命名
  PUTMARKETPAGERENAME: apiData('/api/marketing/landPage/page/rename', 'put'),
  // 复制表单
  POSTMARKETLANPAGECOPY: apiData('/api/marketing/landPage/copy', 'loadPost'),
  // 留资页列表
  GETLEAVEINFOLIST: apiData('/api/marketing/landPage/leaveInfo/list', 'get'),
  // 线索包基础配置
  GETPACKAGEMODULECONFIG: apiData('/api/package/moduleConfig', 'PingGet'),
  // 获取二级标签
  GETPACKAGETAGLEVEL: apiData('/api/package/taglevel', 'get'),

  // 选择数据源
  GETSALESSOURCE: apiData('/api/sales/source/list', 'get'),

  // 数据源列表/api/sales/data/list
  GETSALESSOURCELIST: apiData('/api/sales/data/list', 'get'),

  //精准kp查询
  GETSEARCHKP: apiData('/api/package/search/kp', 'get'),

  // -------客户公海

  // 获取服务商
  KPPROVIDER: apiData('/api/package/search/kp/provider', 'get'),

  //api/customer/transform2Customer
  POSTCUSTRANSCUS: apiData('/api/customer/transform2Customer', 'post'),

  GETCUSPONDLIST: apiData('/api/customer/list/select', 'post'),
  ///api/customer/leaders
  GETCUSTOMERLEADERS: apiData('/api/customer/leaders', 'get'),

  GETCUSTAGSDDIC: apiData('/api/customer/tags/ddic', 'get'),

  getQyTagsDic: apiData('/api/customer/tags/group/ddic', 'get'),

  ///api/customer/info
  POSTCREATECUSINFO: apiData('/api/customer/info', 'post'),
  //api/customer/receivers
  GETCUSTOMERRECEIVERS: apiData('/api/customer/receivers', 'get'),
  GETCUSTOMERRECEIVERSREPEAD: apiData('/api/customer/receivers/uuid', 'get'),

  //api/customer/transform2Others
  POSTCUSTRANSTOOTHERS: apiData('/api/customer/transform2Others', 'post'),

  //高级筛选
  GETCUSLISTFILTER: apiData('/api/customer/list/filter', 'get'),

  //api/customer/follow/records
  GETCUSFOLLOWRECORDS: apiData('/api/customer/follow/records', 'get'),
  //api/customer/info
  DELCUSTOMERINFO: apiData('/api/customer/info', 'delete'),
  //api/customer/info
  PUTCUSTOMERINFO: apiData('/api/customer/info', 'put'),
  //api/customer/info/{id}
  GETCUSTOMERINFOID: apiData('/api/customer/info', 'PingGet'),
  //客户公海批量编辑
  CUSTOMERLOTEEDIT: apiData('/api/customer/batch/info', 'post'),
  //脉脉埋点
  MAIMAIADD: apiData('/api/maimai/add', 'post'),
  LINKEDINADD: apiData('/api/linkedin/add', 'post'),
  SENDCLUEADD: apiData('/api/tracking/send/clue/add', 'post'),
  TRANSFORMADD: apiData('/api/tracking/transform/add', 'post'),

  //客户字段编辑
  EDITFIELD: apiData('/api/customer/tags/info', 'put'),
  CHECKFIELD: apiData('/api/customer/tags/can/del', 'get'),
  POST_MARKETUPLOADRECORDS: apiData(
    '/api/marketing/landPage/upload/records/image',
    'loadPost'
  ),

  // /api/customer/follow/save/records
  POSTFOLLOWSAVERECORDS: apiData('/api/customer/follow/save/records', 'post'),

  // /api/customer/follow/type
  GETFOLLOWTYPE: apiData('/api/customer/follow/type', 'get'),
  MARKEMICRO: apiData('/api/marketing/landPage/convert', 'post'),
  ADDCONVERT: apiData('/api/marketing/landPage/addconvert', 'post'),
  RECORDRADAR: apiData('/api/marketing/landPage/radar', 'get'),

  PERMISSIONMENU: apiData('/api/user/menus', 'get'),
  panelList: apiData('/api/auth-service/permission/panel', 'get'),
  roleCreate: apiData('/api/auth-service/role/create', 'post'),
  roleList: apiData('/api/auth-service/role/list', 'get'),
  roleEdit: apiData('/api/auth-service/role/edit', 'post'),
  roleRemove: apiData('/api/auth-service/role/delete', 'post'),
  roleUser: apiData('/api/auth-service/role/user', 'post'),
  userList: apiData('/api/user/list', 'get'),
  saveDataPermission: apiData('/api/auth-service/data/create', 'post'),
  //外呼系统
  CALLHISTORYLLIST: apiData('/api/waihu/list', 'get'),
  GETCALLHEIOPTION: apiData('/api/waihu/filter', 'get'),
  GETCALLOPTION: apiData('/api/waihu/ddic', 'get'),
  EXPORTCALLHISTORY: apiData('/api/collection/exportwaihu', 'post'),
  APPLYOPENWAIHU: apiData('/api/waihu/openwaihu', 'post'),
  //客户培育
  poolContactUpdate: apiData('/api/contact/pool', 'put'), // 更新联系人
  poolContactCreate: apiData('/api/contact/pool', 'post'), // 新建联系人
  poolContactList: apiData('/api/contact/pool', 'get'), // 联系人列表
  poolContactDelete: apiData('/api/contact/pool', 'delete'), //删除联系人
  poolContactTransform: apiData('/api/contact/pool/transform', 'post'), // 转成客户
  weChatCheck: apiData('/api/contact/pool/wechat/check', 'get'), // 校验企业微信参数
  addWeChatContact: apiData('/api/contact/pool/wechat/import', 'post'), // 导入微信联系人

  couponOrder: apiData('/api/coupon/order', 'post'),
  remarkSearch: apiData('/api/customer/list/filter/remark', 'get'),
  addTagGroup: apiData('/api/customer/tags/group', 'post'), //添加标签组
  editTagGroup: apiData('/api/customer/tags/group', 'put'), //编辑标签组
  deleteTagGroup: apiData('/api/customer/tags/group', 'delete'), //删除标签组
  contactSource: apiData('/api/customer/contact/froms', 'get'), //联系人来源
  haveGoodCustomer: apiData('/api/package/btn/quc', 'get'), //是否存在优选客户

  errorHandel: apiData('/api/front/message', 'post'), //错误上报
  miniWebsite: apiData('/api/landsite/list', 'get'), //微站列表
  miniWebsiteUpdate: apiData('/api/landcard/update', 'post'), //更新微站
  customerDt: apiData('/api/contact/pool/info', 'get'), //客户动态
  companyList: apiData('/api/contact/pool/company/list', 'get'), //联系人培育公司列表

  // 工作台
  dataOne: apiData('/api/workbench/summary', 'get'), //工作台数据概述
  dropdown: apiData('/api/workbench/filter/items/dropdown', 'get'), // 工作台下拉选项
  boarddropdown: apiData('/api/workbench/filter/items/dropdown', 'get'), // 工作台下拉选项
  dataChart: apiData('/api/workbench/select/distribute', 'get'),
  dataChart1: apiData('/api/workbench/select/trend', 'get'),
  dropdown1: apiData('/api/workbench/filter/member/dropdown', 'get'),

  //外呼数据看板
  callInfoPath: apiData('/api/workbench/waihu', 'get'),
  callBoardOption: apiData('/api/workbench/waihu/filter/items/dropdown', 'get'),
  callDataChart: apiData('/api/workbench/waihu/trend', 'get'),
  callDataTable: apiData('/api/workbench/waihu/branch', 'get'),
  exportCallList: apiData('/api/collection/waihuPanel', 'post'),

  dataHk: apiData('/api/workbench/ai', 'get'),
  dataWz: apiData('/api/workbench/site', 'get'),
  dataPy: apiData('/api/workbench/breed', 'get'),
  dataZh: apiData('/api/workbench/customer', 'get'),
  dataLd: apiData('/api/workbench/funnel', 'get'),

  // 下载任务
  taskList: apiData('/api/download/list', 'get'),
  uploadList: apiData('/api/collection/upload/list', 'get'),

  // 是否领取
  isGeted: apiData('/api/package/consumed', 'get'),

  // 转入touch模块联系人
  transContact: apiData('/api/customer/contact/addtouch', 'post'),

  // 热力图
  hotMap: apiData('/api/es/company/hot', 'get'),

  // 地图获客
  searchCompanyForMap: apiData('/api/es/company/map', 'get'),

  // 地图模块成立时间
  establishTimeData: apiData('/api/es/company/establishTime', 'get'),

  // 根据地址查询经纬度
  searchAddressForMap: apiData('/api/es/company/geo', 'get'),

  // 客户公海导出
  cusExport: apiData('/api/collection/exportcus', 'post'),

  // 钉钉免登录
  ddAuth: apiData('/api/dingding/auth/account', 'get'),

  // 查看组织架构
  viewOrganization: apiData('/api/dingding/department', 'get'),

  // 精准查kp下拉搜索
  searchAuto: apiData('/api/es/company/fuzzy', 'get'),

  // 培育池筛选项
  booklistFilter: apiData('/api/collection/filter', 'get'),

  // 客户地图埋点
  maidianMap: apiData('/api/tracking/menu/map', 'post'),

  // 自动转派列表
  autoTransferList: apiData('/api/transfer/select', 'get'),

  // 添加自动转派任务
  addTranffer: apiData('/api/transfer/add', 'post'),

  // 删除转派任务
  deleteTransfer: apiData('/api/transfer/del', 'delete'),

  // 更新转派任务
  updateTransfer: apiData('/api/transfer/update', 'put'),

  // 转派条件
  selectTransfer: apiData('/api/transfer/dropdown', 'get'),

  // 用户所有可配置线索包
  packageAll: apiData('/api/package/all', 'get'),

  // 代理商详情
  agentDetail: apiData('/api/agent/id', 'get'),

  // 代理商列表
  agentList: apiData('/api/agent/list', 'get'),

  // 编辑代理商
  agentEdit: apiData('/api/agent/edit', 'put'),

  // 新注册接口
  register: apiData('/api/auth/regist/sign/up', 'loadPost'),

  ttLogin: apiData('/api/feishu/login', 'post'),

  // 联系人管理
  contactManage: apiData('/api/contact/manage/list', 'get'),

  // 商机列表
  nicheList: apiData('/api/niche/select', 'get'),

  // 添加商机
  addNiche: apiData('/api/niche/add', 'post'),

  // 编辑商机
  editNiche: apiData('/api/niche/update', 'post'),

  // 删除商机
  delNiche: apiData('/api/niche/del', 'post'),

  // 商机数据权限
  leaderNiche: apiData('/api/niche/leader', 'get'),

  // 商机筛选
  nicheFilter: apiData('/api/niche/filter', 'get'),

  // 刷新云服务商
  refreshIdc: apiData('/api/package/refresh/provider', 'get'),

  // 查看发票信息
  invDetail: apiData('/api/inv/info', 'get'),

  // 添加或修改发票
  editInv: apiData('/api/inv/addmod', 'post'),

  // 跟进记录列表
  followRecordList: apiData('/api/follow/manage/list', 'get'),

  // 跟进记录高级筛选
  followRecordFilter: apiData('/api/follow/manage/filter', 'get'),

  // 锁定合同
  lockContract: apiData('/api/customer/contract/isedit', 'post'),

  // 售后
  afterSales: apiData('/api/aftersale/list', 'get'),

  // 售后高级筛选
  afterSalesFilter: apiData('/api/aftersale/filter', 'get'),

  // 更新商机阶段
  updateNicheState: apiData('/api/niche/update/state', 'post'),

  afterSalesOption: apiData('/api/aftersale/dropdown', 'get'),

  // 是否显示外呼提示
  showWaihuTip: apiData('/api/waihu/notice/get', 'get'),

  // 关闭提示
  closeShowWaihuTip: apiData('/api/waihu/notice/close', 'post'),

  // 线索包市场tab
  marketTab: apiData('/api/market/tab', 'get'),

  // 线索包市场列表
  marketList: apiData('/api/market/content', 'get'),

  // 操作线索包
  marketBook: apiData('/api/market/booking', 'post'),

  autoWaiHuList: apiData('/api/waihu/task/list', 'get'),

  importWaiHu: apiData('/api/waihu/task/import', 'post', {
    'Content-Type': 'multipart/form-data'
  }),

  settingWaiHu: apiData('/api/waihu/task/config', 'get'),

  editWaiHuSetting: apiData('/api/waihu/task/config', 'post'),

  addWaHuTaskFromKey: apiData('/api/waihu/task/add', 'post'),

  callWaiHu: apiData('/api/waihu/task/call', 'post'),

  callDel: apiData('/api/waihu/task/del', 'post'),

  noTipEdit: apiData('/api/phone/close/mark/notice', 'post'),

  postSim: apiData('/api/waihu/addsim', 'post'),

  canCall: apiData('/api/waihu/verfiy', 'post'),

  // 数据看板表格
  tableDtData: apiData('/api/workbench/select/table', 'get'),

  // 监控列表
  monitorList: apiData('/api/monitor/list', 'get'),

  // 移除监控
  delMonitor: apiData('/api/monitor/del', 'delete'),

  // 添加监控
  addMonitor: apiData('/api/monitor/add', 'post'),

  // 监控日志
  monitorLog: apiData('/api/monitor/log/list', 'get'),

  monitorDetail: apiData('/api/monitor/log/one', 'get'),

  isMonitor: apiData('/api/monitor/done', 'get'),

  esHistory: apiData('/api/es/company/history', 'get'),

  esCloudHistory: apiData('/api/es/company/cloud', 'get'),

  onCloudService: apiData('/api/es/company/cloud/stage', 'get'),

  applySim: apiData('/api/waihu/applysim', 'post'),

  // 收款单列表
  returnList: apiData('/api/return/list', 'get'),

  // 收款单高级筛选
  returnDrown: apiData('/api/return/list/drown', 'get'),

  getContractNum: apiData('/api/customer/contract/getnum', 'get'),

  removeContractNum: apiData('/api/customer/contract/decre', 'get'),

  // 删除首款账单
  delPayBack: apiData('/api/return/del', 'post'),

  // 生成收款管理的编号
  getPayBackNum: apiData('/api/return/getnum', 'get'),

  // 收款账单编号减1
  removeBackPayNum: apiData('/api/return/decre', 'get'),

  // 添加收款单
  addPayBack: apiData('/api/return/add', 'post'),

  // 修改收款单
  editPayBack: apiData('/api/return/update', 'post'),

  // 数据下钻
  runIn: apiData('/api/workbench/drill', 'pathGet'),

  callSlot: apiData('/api/waihu/callSlot', 'post'),

  batchLq: apiData('/api/package/consumption/batch', 'post'),

  packageListNew: apiData('/api/package/manage/list', 'get'),

  packageListNews: apiData('/api/package/list', 'get'),

  packageEditNew: apiData('/api/package/manage/edit', 'post'),

  packageOne: apiData('/api/package/manage/one', 'get'),

  contractHeaderShow: apiData('/api/customer/contract/header/list', 'get'),

  contractHeaderUpdate: apiData('/api/customer/contract/header/info', 'post'),

  addNichePz: apiData('/api/niche/pzinfo', 'post'),

  getAllSales: apiData('/api/niche/allSale', 'get'),

  getAllContact: apiData('/api/niche/contactAll', 'get'),

  heightSearchTypeList: apiData('/api/es/company/find/filter/cardinal', 'get'),

  heightSearchFilter: apiData('/api/es/company/find/filter/details', 'get'),

  supplierList: apiData('/api/supplier/list', 'get'),

  supplierAdd: apiData('/api/supplier/add', 'post'),

  supplierEdit: apiData('/api/supplier/edit', 'put'),

  supplierNum: apiData('/api/supplier/sysNum', 'get'),

  supplierDel: apiData('/api/supplier/del', 'delete'),

  purchaseList: apiData('/api/purchase/list', 'get'),

  purchaseEdit: apiData('/api/purchase/update', 'post'),

  purchaseAdd: apiData('/api/purchase/info', 'post'),

  purchaseDel: apiData('/api/purchase/del', 'post'),

  newPhoneCall: apiData('/api/cyj/start', 'post'),

  newPhoneStatus: apiData('/api/cyj/state', 'get'),

  newPhoneHangUp: apiData('/api/cyj/end', 'post'),

  childList: apiData('/api/childUser/account/list', 'get'),

  customerColumn: apiData('/api/field/header/list', 'get'),

  editCustomerColumn: apiData('/api/field/header/info', 'post'),

  targetList: apiData('/api/workbench/target/list', 'get'),
  updateTargetList: apiData('/api/workbench/target/info', 'post'),

  processTouch: apiData('/api/data/analysis/process/touch', 'get'),

  processTranslation: apiData('/api/data/analysis/process/translation', 'get'),

  processReturn: apiData('/api/data/analysis/process/return', 'get'),

  warfareSign: apiData('/api/data/analysis/warfare/sign', 'get'),

  warfareFinancial: apiData('/api/data/analysis/warfare/financial', 'get'),

  warfareNewRenew: apiData('/api/data/analysis/warfare/newRenew', 'get'),

  saleRankSignContract: apiData(
    '/api/data/analysis/saleRank/signContract',
    'get'
  ),

  saleRankReturnRank: apiData('/api/data/analysis/saleRank/returnRank', 'get'),

  saleRankAchievement: apiData(
    '/api/data/analysis/saleRank/achievement',
    'get'
  ),

  getJmPhone: apiData('/api/contact/pool/phone', 'get'),

  getSyMenus: apiData('/api/contact/pool/sidebar', 'get'),

  batchPool: apiData('/api/contact/pool/batch', 'put'),

  createOrEditJcl: apiData('/api/decision', 'put'),

  delJcl: apiData('/api/decision', 'delete'),

  jclList: apiData('/api/decision/list', 'get'),

  searchDomainBase: apiData('/api/domain/baseInfo', 'get'),

  getDomain: apiData('/api/domain/subordinate', 'get'),

  getCdnService: apiData('/api/domain/cdn', 'get'),

  getYunService: apiData('/api/domain/cloud', 'get'),

  getEarnNum: apiData('/api/domain/remainPoint', 'post'),

  getEarnNumPoint: apiData('/api/cloud/remainPoint', 'post'),

  addEarnNum: apiData('/api/wechat/point/recharge', 'get'),

  yunServiceHistory: apiData('/api/cloud/idc', 'get'),

  graph: apiData('/api/cloud/graph', 'get'),

  customize: apiData('/api/customize', 'get'),
  customizeDel: apiData('/api/customize', 'delete'),
  customizePost: apiData('/api/customize', 'JsonPost'),
  departmentList: apiData('/api/department/list', 'get'),
  addDepartment: apiData('/api/department', 'JsonPost'),
  updateDepartment: apiData('/api/department', 'put'),
  packageInsight: apiData('/api/package/insight', 'get'),
  insightMould: apiData('/api/insight/mould', 'get'),
  insightBind: apiData('/api/band/insight', 'post'),
  insightFilter: apiData('/api/insight/rz/filter', 'get'),
  softwareFilter: apiData('/api/insight/software/filter', 'get'),
  insightSearch: apiData('/api/insight/rz/search', 'get'),
  insightList: apiData('/api/insight/rz/list', 'get'),
  softwareList: apiData('/api/insight/software/list', 'get'),
  wxLogin: apiData('/api/oauth/wechat/login', 'post'),
  bindWx: apiData('/api/oauth/wechat/bind', 'post'),
  wxVery: apiData('/api/oauth/wechat/verify', 'post'),
  parkInsightFilter: apiData('/api/insight/park/filter', 'get'),
  parkList: apiData('/api/insight/park', 'get'),
  parkPageList: apiData('/api/insight/park/list/page', 'JsonPost'),
  parkDetailDesc: apiData('/api/insight/park/info', 'get'),
  parkCompanyList: apiData('/api/insight/park/list', 'JsonPost'),
  parkMap: apiData('/api/insight/map', 'get'),
  overSeaFilter: apiData('/api/insight/overseas/filter', 'get'),
  overSeaChart: apiData('/api/insight/overseas', 'get'),
  overSeaList: apiData('/api/insight/overseas/list', 'get'),
  refreshPayBack: apiData('/api/return/refresh', 'get'),
  overSeaDdic: apiData('/api/insight/overseas/ddic', 'get'),
  messagePush: apiData('/api/user/messages/list', 'get'),
  clearMessagePush: apiData('/api/user/messages/read', 'post'),
  //数据过滤模块
  appFilterNiuBe: apiData('/api/insight/app/ddic', 'get'),
  appInsightList: apiData('/api/insight/app/list', 'get'),
  appFilter: apiData('/api/insight/app/filter', 'get'),
  applicationFilter: apiData('/api/insight/app/developer/filter', 'get'),
  applicationChart: apiData('/api/insight/app/developer', 'get'),
  applicationList: apiData('/api/insight/app/developer/list', 'get'),
  parseMobile: apiData('/api/customer/contact/phone/num', 'get'), //手机号解密显示
  getConfigData: apiData(`/api/ddic/config`, 'get'), //配置字典表

  getConcatHistory: apiData(`/api/customer/contract/update/his`, 'get'), //查看合同历史
  getOpenApi: apiData(`/api/open/info`, 'get'), //查看openApi
  getLogList: apiData(`/api/open/info/his`, 'get'), //查看openApi历史
  getBandFilter: apiData(`/api/insight/industry/filter`, 'get'), //榜单过滤
  getBandList: apiData(`/api/insight/industry/list`, 'get'), //榜单历史
  getBandDetail: apiData(`/api/industry/bd/company/list`, 'JsonPost'), //榜单详情
  listDetailData: apiData(`/api/insight/industry/bdinfo`, 'get'), //榜单信息
  currentVersion: apiData(`/api/system/version`, 'get'), //当前版本号
  pyToast: apiData(`/api/system/alert`, 'post'), //弹窗控制
  latestPercent: apiData(`/api/download/new`, 'get'), //数据导出进度条
  groupList: apiData(`/api/insight/enterprise/group/list`, 'get'), //族群洞察列表
  groupListDetail: apiData(`/api/insight/enterprise/group/eg`, 'get'), //族群洞察列表详情
  groupListDrown: apiData(`/api/insight/enterprise/group/list/eg`, 'get'), //族群洞察成员列表
  groupListBand: apiData(`/api/insight/enterprise/group/list/bd`, 'get'), //族群洞察榜单列表
  groupListApp: apiData(`/api/insight/enterprise/group/list/app`, 'get'), //族群洞察app列表
  groupListCount: apiData(`/api/insight/enterprise/group/list/count`, 'get'), //族群洞察详情数据统计
  setMemoryParams: apiData(`/api/filter/router/info`, 'JsonPost'), //设置记忆参数
  getMemoryParams: apiData(`/api/filter/router`, 'get'), //获取记忆参数
  getTagList: apiData(`/api/insight/atlas`, 'get'), //获取行业图谱
  schemaMemberList: apiData(`/api/insight/tag/list`, 'get'), //标签下的成员企业
  schemaDetail: apiData(`/api/insight/tag`, 'get'), //标签下详情
  schemaDetailCount: apiData(`/api/insight/tag/item/cnt`, 'get'), //标签下详情数量
  schemaIndexData: apiData(`/api/insight/atlas/head/rz`, 'get'), //标签下详情
  schemaIndexItem: apiData(`/api/insight/atlas/head/item`, 'get'), //标签分类
  marketConfig: apiData(`/api/insight/tag/filter`, 'get'), //上市企业分类
  dataDiagram: apiData(`/api/insight/tag/rankChart`, 'get'),
  investRecord: apiData(`/api/insight/tag/rzhis`, 'get'),
  investGroupList: apiData(`/api/insight/agency/list`, 'get'),
  investGroupDetail: apiData(`/api/insight/agency/info`, 'get'),
  investDetailFilter: apiData(`/api/insight/agency/filter`, 'get'),
  investDetailList: apiData(`/api/insight/agency/company/list`, 'get'),
  getRouterUrl: apiData(`/api/system/router`, 'get'),
  getInvestEventInfo: apiData(`/api/insight/event/info`, 'get'),
  getInvestEventFilter: apiData(`/api/insight/event/filter`, 'get'),
  getInvestEventChart: apiData(`/api/insight/event/rankChart`, 'get'),
  getInvestEventList: apiData(`/api/insight/event/list`, 'get'),
  getFaFilter: apiData(`/api/insight/fa/filter`, 'get'),
  getFaList: apiData(`/api/insight/fa/list`, 'get'),
  getFatherNode: apiData(`/api/insight/tag/findParent`, 'get'),
  getChartOrderNo: apiData(`/api/atlas/order`, 'post'),
  getVirtualOrderNo: apiData(`/api/atlas/order2`, 'post'),
  getChartInsight: apiData(`/api/applet/atlas/rankChart`, 'get'),
  getCloudList: apiData(`/api/applet/atlas/gyspanel`, 'get'),
  getNetCheck: apiData(`/api/domain/check`, 'get'),
  getMapCheck: apiData(`/api/domain/center`, 'get'),
  getRefreshCount: apiData(`/api/applet/atlas/totalCount`, 'JsonPost'),
  getCloudLogo: apiData(`/api/cloud/idc/logo`, 'get'),
  getNewCloudList: apiData(`/api/domain/subordinate/v2`, 'get'),
  getDomainRefresh: apiData(`/api/domain/refresh`, 'get'),
  getStoreData: apiData(`/api/branch/count/map`, 'JsonPost'),
  getStoreOpen: apiData(`/api/branch/count/trend/open`, 'JsonPost'),
  getStoreClose: apiData(`/api/branch/count/trend/close`, 'JsonPost'),
  getStoreBusiness: apiData(`/api/branch/count/trend/normal`, 'JsonPost'),
  getOverSeaData: apiData(`/api/companyTagOversea/list`, 'JsonPost'),
  getDistrictData: apiData(`/api/dataCircle/map`, 'JsonPost'),
  groupListStore: apiData(`/api/insight/enterprise/group/list/branch`, 'get'),
  getChainRadarMenu: apiData(`/api/tag/info/chainRadarPanel`, 'JsonPost'),
  getChainRadarList: apiData(`/api/company/tag/chain/radar/list`, 'JsonPost'),
  getCityLevel: apiData(`/api/branch/count/area/detail`, 'JsonPost'),
  getNewsList: apiData(`/api/branch/count/news`, 'JsonPost'),
  getNewsListFilter: apiData(`/api/branch/count/news/filter`, 'JsonPost'),
  getNewsListRefresh: apiData(`/api/branch/count/news/refresh`, 'JsonPost'),
  getBrandFalse: apiData(`/api/branch/count/counterfeit`, 'JsonPost'),
  getBrandCharts: apiData(`/api/branch/count/news/chart`, 'JsonPost'),
  getBrandDetail: apiData(`/api/dataStoreBrand/info`, 'get'),
  getShopCircle: apiData(`/api/chain/radar/popular/shop`, 'JsonPost'),
  getCitySelect: apiData(`/api/chain/radar/filter/city`, 'JsonPost'),
  getAiCircle: apiData(`/api/chain/radar/ai/choice`, 'JsonPost'),
  getAreaRank: apiData(`/api/chain/radar/area/rank`, 'JsonPost'),
  getCityRank: apiData(`/api/chain/radar/city/level`, 'JsonPost'),
  getRankDetail: apiData(`/api/chain/radar/area/detail`, 'JsonPost'),
  getBrandLink: apiData(`/api/chain/radar/popular/shop/recommend`, 'JsonPost'),
  getBrandFilter: apiData(
    `/api/chain/radar/popular/shop/brandFilter`,
    'JsonPost'
  ),
  getBrandTotal: apiData(`/api/chain/radar/popular/shop/settled`, 'JsonPost'),
  getWxScanError: apiData(`/api/oauth/wechat/code/users`, 'post'),
  getStoreTotal: apiData(`/api/chain/radar/store/list`, 'JsonPost'),
  getStoreFloor: apiData(`/api/chain/radar/brand/floor`, 'JsonPost'),
  getFloorList: apiData(`/api/chain/radar/brand/floor/list`, 'JsonPost'),
  getConsumeList: apiData(`/api/tag/info/consumerIndustry`, 'JsonPost'),
  getConsumeMap: apiData(`/api/consumer/industry/map`, 'JsonPost'),
  getConsumeHot: apiData(`/api/consumer/industry/hot`, 'JsonPost'),
  getConfigMenu: apiData(`/api/tag/info/menu`, 'JsonPost'),
  getPointNearby: apiData(`/api/site/select/point/info`, 'JsonPost'),
  getLineNearby: apiData(`/api/site/select/point/route`, 'JsonPost'),
  getChildMenu: apiData(`/api/site/select/drop/child`, 'JsonPost'),
  getPointMapData: apiData(`/api/site/select/map`, 'JsonPost'),
  getHotMapData: apiData(`/api/site/select/hot`, 'JsonPost'),
  getGeomancyDetail: apiData(`/api/site/select/point/list`, 'JsonPost'),
  getGeomancyHistory: apiData(`/api/site/select/history`, 'get'),
  getRangeHot: apiData(`/api/site/select/hot/circle`, 'JsonPost'),
  getSexChart: apiData(`/api/site/select/chart`, 'JsonPost'),
  getShopInfo: apiData(`/api/site/select/shop/lease`, 'JsonPost'),
  getPointConfig: apiData(`/api/site/select/point/info2`, 'JsonPost'),
  getAddressNew: apiData(`/api/area/list2`, 'get'),
  getIndustryClassify: apiData(`/api/ai/select/industry`, 'JsonPost'),
  getAddrSure: apiData(`/api/ai/select/map`, 'JsonPost'),
  getDefaultAddr: apiData(`/api/area/name/level`, 'get'),
  getRecordAddr: apiData(`/api/ai/select/address`, 'get'),
  getAiFloor: apiData(`/api/chain/radar/brand/floor/types`, 'JsonPost'),
  getPcQrCode: apiData(`/api/branch/count/QRCode`, 'JsonPostImg'),
  getComReMapList: apiData(`/api/branch/count/follow/competition`, 'JsonPost'),
  getAiMyList: apiData(`/api/ai/wenan/article/list`, 'JsonPost'),
  getMassAiList: apiData(`/api/ai/wenan/talking/list`, 'get'),
  getAiCreated: apiData(`/api/ai/wenan/talking/build`, 'JsonPost'),
  getAiSaved: apiData(`/api/ai/wenan/talking/save`, 'JsonPost'),
  getAiDelete: apiData(`/api/ai/wenan/talking/del`, 'get'),
  getAiSelectAddress: apiData(`/api/site/select/chubei/address`, 'JsonPost'),
  getAiPrintResult: apiData(`/api/ai/wenan/azure/talk`, 'JsonPost'),
  getPeopleCount: apiData(`/api/site/select/point/people`, 'JsonPost'),
  getSimilarBrandList: apiData(`/api/dataStoreBrand/kc/similar`, 'get'),
  getRecommendBrand: apiData(
    `/api/site/select/recommend/point/list`,
    'JsonPost'
  ),
  getInvestCitySelect: apiData(`/api/chain/business/filter/city`, 'JsonPost'),
  getInvestData: apiData(`/api/chain/business/map`, 'JsonPost'),
  getInvestDetailData: apiData(`/api/chain/business/map/info`, 'JsonPost'),
  getBusinessInvestStatus: apiData(`/api/chain/business/info`, 'get'),
  getConfigInvestStatus: apiData(`/api/chain/business/filter/status`, 'get'),
  getSetInvestStatus: apiData(`/api/chain/business/edit`, 'JsonPost'),
  getMonitorList: apiData(`/api/enter/dev/list`, 'JsonPost'),
  getQyTagFilter: apiData(`/api/enter/dev/filter`, 'get'),
  getModuleConfig: apiData(`/api/user/menus/module`, 'get'),
  getPolicyList: apiData(`/api/policy/list`, 'JsonPost'),
  getYqList: apiData(`/api/policy/park2`, 'JsonPost'),
  getYqTags: apiData(`/api/policy/tags`, 'get'),
  getPolicyDetail: apiData(`/api/policy/info`, 'get'),
  getPolicyCloud: apiData(`/api/policy/wordcloud`, 'get'),
  getScreenConfig: apiData(`/api/screen/config`, 'get'),
  getScreenData: apiData(`/api/screen/data`, 'JsonPost'),
  getScreenTagTree: apiData(`/api/policy/tag/park/tree`, 'get'),
  getVisualList: apiData(`/api/visual/list`, 'get'),
  getVisualWorks: apiData(`/api/visual/company-works`, 'get'),
  getChartsApiDetail: apiData(`/api/visual/company-works/info`, 'get'),
  getVisualComponents: apiData(`/api/visual/card-style`, 'get'),
  getVisualTest: apiData(`/api/visual/test`, 'get'),
  getVisualTree: apiData(`/api/screen/tag/tree`, 'get'),
  getPackageTag: apiData(`/api/screen/tag`, 'JsonPost'),
  getScreenTagChart: apiData(`/api/screen/rankChart`, 'get'),
  getScreenFinanceList: apiData(`/api/screen/rz/list`, 'JsonPost'),
  getScreenEdit: apiData(`/api/visual/reset-card`, 'loadPost'),
  getScreenCardDetail: apiData(`/api/screen/config/info`, 'get'),
  getVisualTemplate: apiData(`/api/visual/example/list`, 'get'),
  getTempAddScreen: apiData(`/api/screen/createScreen`, 'JsonPost'),
  getTempCreateScreen: apiData(`/api/screen/screenSetting`, 'JsonPost'),
  getVisualChild: apiData(`/api/visual/screen/tab_plate`, 'get'),
  getScreenId: apiData(`/api/screen/catch/screenId`, 'get'),
  getLoginConfig: apiData(`/api/system/page/config`, 'get'),
  getFlexibleConfig: apiData(`/api/visual/card-style/group`, 'get'),
  getFlexibleList: apiData(`/api/screen/layout`, 'get'),
  getFlexSingleSave: apiData(`/api/visual/save/card`, 'loadPost'),
  getFlexAllSave: apiData(`/api/visual/save/card/batch`, 'JsonPost'),
  getFlexDelete: apiData(`/api/visual/del/card`, 'delete'),
  getHxLogin: apiData(`/api/auth/hx/login`, 'loadPost'),
  getWorksDelete: apiData(`/api/screen/del`, 'delete'),
  getTpDialog: apiData(`/api/screen/tag/tree/company`, 'JsonPost'),
  getCardDetail: apiData(`/api/screen/cardconfig/info`, 'get'),
  getQyDetailExport: apiData(`/api/collection/exportPortrait`, 'get'),
  getPageCtrl: apiData(`/api/screen/config/info`, 'get')
}
