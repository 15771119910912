<template>
  <div class="hx-schema-page">
    <PeScroll ref="scroll" @handleScrollBottom="loadMore">
      <div v-loading="mainLoading">
        <div v-if="schemaList.length" class="list">
          <div class="item" v-for="item in schemaList" :key="item.id">
            <div class="top">
              <div class="top-fl">
                <div class="name">{{ item.tagName }}</div>
              </div>
              <div class="top-fr">
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleDetail(item)"
                >
                  <i class="el-icon-share el-icon--left" />图谱详情</el-button
                >
              </div>
            </div>
            <div class="desc">{{ item.tagDesc }}</div>

            <div class="tag-wrap">
              <div class="tag-fl">
                <img
                  v-if="item.tagImgUrl"
                  :src="item.tagImgUrl"
                  class="tag-img"
                />
                <Icon v-else type="img" peClass="default" class="tag-img" />
              </div>
              <div class="tag-fr">
                <div class="num-list">
                  <div v-if="item.tagCnt != 0" class="num-item">
                    <div class="num-name">企业数</div>
                    <div class="num-value-show">
                      {{ item.tagCnt }}
                    </div>
                  </div>
                  <div v-if="item.vcEventCnt != 0" class="num-item">
                    <div class="num-name">融资事件</div>
                    <div class="num-value-show">
                      {{ item.vcEventCnt }}
                    </div>
                  </div>
                  <div v-if="item.marketGuCnt != 0" class="num-item">
                    <div class="num-name">上市企业数</div>
                    <div class="num-value-show">
                      {{ item.marketGuCnt }}
                    </div>
                  </div>

                  <div v-if="item.vcSumAmount != 0" class="num-item">
                    <div class="num-name">融资金额(￥亿元)</div>
                    <div class="num-value" :style="getFilter(item)">
                      {{ item.vcSumAmount }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <NoData v-else />
      </div>
    </PeScroll>

    <PeDialog
      :pe-dialog-show="show"
      :pe-full-screen="true"
      :pe-dialog-custom="true"
      @handlePeDialogClose="show = false"
      pe-dialog-class="screen-dialog"
      pe-dialog-title="行业图谱"
    >
      <div class="dialog-wrap">
        <TreeChart
          ref="treeChart"
          :dataJson="treeDataList"
          :treeDataText="treeDataText"
          @clickNode="handleNodeClick"
        />
      </div>
    </PeDialog>

    <pe-dialog
      :pe-dialog-show="visible"
      :pe-dialog-title="handleDialogTitle"
      @handlePeDialogClose="visible = false"
    >
      <pe-table
        :pe-table-data="tableObj.datas"
        :pe-column-list="tableObj.columns"
        :pe-page="peParams"
        :pe-footer="false"
        table-h="500"
        @handleCurrentPePage="handleCurrentPePage"
        @handleSizePeChange="handleSizePeChange"
      >
        <template v-slot:companyName="{ scope }">
          <div
            class="company-name-click"
            @click="handleEnter(scope.data, scope.index)"
          >
            {{ scope.data.companyName }}
          </div>
        </template>
      </pe-table>
    </pe-dialog>
  </div>
</template>

<script>
import { getVisualTree, getPackageTag, getTpDialog } from '@/api/module'
import TreeChart from '@/components/Peculiar/tree/shrink-tree'
import PeDialog from '@/components/Peculiar/dialog'
import PeScroll from '@/components/Peculiar/scroll'
import PeTable from '@/components/Peculiar/table'
import { enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'HxScreenSchema',
  data() {
    return {
      visible: false,
      show: false,
      schemaList: [],
      pePageNo: 1,
      pePageTotal: 0,
      mainLoading: false,
      treeDataList: [],
      tagId: '',
      treeDataText: '',
      tableObj: {
        datas: [],
        columns: []
      },
      peParams: {
        packageId: '',
        tagId: '',
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      currentInfo: {}
    }
  },

  props: {
    packageId: {
      type: Number,
      default: 0
    }
  },

  watch: {
    packageId: {
      handler() {
        this.getItem()
        console.log(111555)
      },
      immediate: true
    }
  },
  computed: {
    handleDialogTitle() {
      const { tagName, total } = this.currentInfo
      return `${tagName}(${total})`
    }
  },

  components: {
    PeDialog,
    PeScroll,
    TreeChart,
    PeTable
  },

  methods: {
    handleEnter(data, index) {
      enterCompanyDetail(data, this.tableObj.datas, index, this.packageId)
    },

    handleCurrentPePage(pageNo) {
      Object.assign(this.peParams, { pageNo })
      this.handleTableList()
    },

    handleSizePeChange(pageSize) {
      console.log(pageSize)
      Object.assign(this.peParams, { pageSize, pageNo: 1 })
      this.handleTableList()
    },

    async handleTableList() {
      const { data } = await getTpDialog(this.peParams)
      console.log(data, 'data123')
      this.tableObj = data
      this.peParams.total = data.page?.total
    },

    handleNodeClick(item) {
      Object.assign(this.peParams, {
        pageNo: 1,
        pageSize: 10,
        packageId: this.packageId,
        tagId: item.id
      })
      this.handleTableList()
      this.currentInfo = item
      console.log(item, 'sss')
      this.visible = true
    },

    loadMore() {
      if (this.pePageNo * 10 > this.pePageTotal) {
        return
      }
      this.pePageNo++
      this.getItem(true)
    },

    async getItem(flag = false) {
      this.mainLoading = true
      const { data } = await getPackageTag({
        pageNo: this.pePageNo,
        pageSize: 10,
        packageId: this.packageId
      })
      console.log(data, 'packList')
      this.pePageTotal = data?.page?.total
      this.mainLoading = false
      if (flag) {
        this.schemaList = this.schemaList.concat(data?.datas)
      } else {
        this.schemaList = data?.datas
      }
      setTimeout(() => {
        this.mainLoading = false
      }, 800)
    },

    getFilter(data) {
      if (data.isEncrypt == 1) {
        return null
      } else {
        return `filter:blur(${10}px) brightness(0.1) opacity(50%)`
      }
    },

    handleDetail(data) {
      console.log(data)
      this.tagId = data.id
      this.getTreeData()
      this.show = true
    },

    handleTreeData(treeData) {
      treeData.name = treeData?.tagName
      if (treeData.children) {
        treeData.children.forEach(item => {
          this.handleTreeData(item)
        })
      }
      this.treeData = treeData
      //处理数据
      const list = []
      list.push(treeData)
      this.treeDataList = list
      this.treeDataText = `${list[0].id}`
    },

    async getTreeData() {
      // debugger
      const { data } = await getVisualTree({
        packageId: this.packageId,
        tagId: this.tagId
      })
      console.log(data, 'treeData')
      if (data.length) {
        this.handleTreeData(data[0])
      }
    }
  }
}
</script>
<style lang="scss">
.hx-schema-page {
  height: calc(100vh - 200px);

  .el-dialog__body {
    max-height: inherit;
    padding: 0;
    overflow: hidden;
  }

  .screen-dialog {
    background: #333;
  }

  .el-dialog__footer {
    display: none;
  }

  .dialog-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-fl {
    position: fixed;
    top: 50%;
    left: 1.5%;
    transform: translateY(-50%);
  }

  .icon-fr {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 2.5%;
  }

  .pe-table {
    margin-top: 0;
  }
}
</style>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.hx-schema-page {
  .search-schema {
    display: flex;
    margin-bottom: 10px;
    position: relative;
    z-index: 9999;
  }
  ::v-deep .el-scrollbar {
    position: absolute;
    left: 10px;
    top: 60px;
    right: 5px;
    bottom: 0;
  }

  .item {
    border: 2px solid #f1f1f1;
    padding: 10px;
    background: #fff;
    &:not(:first-child) {
      margin: 10px 0;
    }
    &:hover {
      border: 2px solid $main-color;
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .top-fl {
        display: flex;
        align-items: center;
      }
      .top-fr {
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          color: $main-color;
        }
        ::v-deep .pe-icon {
          color: $main-color;
          margin-left: 10px;
        }
      }

      .name {
        font-weight: 700;
        font-style: normal;
        font-size: 20px;
        color: #000;
      }
    }
    .tag-wrap {
      display: flex;
      align-items: center;
      .tag-img {
        width: 120px;
        height: 120px;
      }
      .tag-fr {
        margin-left: 20px;
      }
    }

    .desc {
      font-size: 13px;
      margin-top: 10px;
    }
    .num-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: -30px;
      .num-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        margin: 10px 30px;
        &:not(:last-child) ::after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -30px;
          width: 2px;
          height: 30px;
          background: #d8d8d8;
        }

        .num-name {
          font-size: 14px;
        }
        .num-value {
          font-size: 30px;
          margin-top: 2px;
        }
        .num-value-show {
          font-size: 30px;
          margin-top: 2px;
        }
      }
    }
  }

  .no-data {
    color: #a9a9a9;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .company-name-click {
    margin: 0 10px;
    color: #0084ff;
    cursor: pointer;
  }

  .company-tz {
    margin: 0 0 0 10px;
  }
}
</style>
