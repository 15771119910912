<template>
  <div class="productRelationGraph-main">
    <div class="main-graph" ref="myPage" @mousemove="onMouseMove">
      <RelationGraph
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="onNodeClick"
        v-loading="graphLoad"
      >
        <template #node="{ node }">
          <i @click="jumpCompany(node.id)" class="el-icon-office-building"></i>
        </template>

        <template #graph-plug>
          <div
            v-if="isShowNodeTips"
            class="c-tips"
            :style="{
              left: nodeTipsPosition.x + 'px',
              top: nodeTipsPosition.y + 'px'
            }"
          >
            <div>{{ currentNode.data.cname }}</div>
          </div>
        </template>
      </RelationGraph>
    </div>

    <div
      class="touch-no-receive"
      v-if="!otherParams.isReceive && otherParams.shade"
      v-loading="getLoading"
    >
      <div class="touch-no-cont">
        <div class="touch-no-icon">
          <span
            style="color: #008cd9; text-decoration: underline; cursor: pointer"
            @click="getReveiveKey('phone')"
            >领取</span
          >
          <span>以查看所有信息</span>
        </div>
      </div>
    </div>
    <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showDetail">
      <div slot="modalCont">
        <p v-html="recruitStr" style="padding: 20px 0"></p>
      </div>
      <div slot="submit">
        <div class="grasp_go_pay">
          <span @click="graspGoPay">关闭</span>
        </div>
      </div>
    </dia-model>
  </div>
</template>

<script>
import diaModel from '@/components/Model'
import Mixin from './mixin'
import RelationGraph from 'relation-graph'
import { getComDetailModel } from '@/api/thread'
import { throttle } from 'lodash'
export default {
  name: 'cloudList',

  components: {
    diaModel,
    RelationGraph
  },

  mixins: [Mixin],

  data() {
    return {
      nodeTipsPosition: {
        x: 0,
        y: 0
      },
      isShowNodeTips: false,
      currentNode: {},
      graphLoad: false,
      showDetail: false,
      graspData: {},
      recruitStr: '',
      graphOptions: {
        allowSwitchLineShape: true,
        allowSwitchJunctionPoint: true,
        defaultJunctionPoint: 'border',
        allowShowMiniToolBar: true,
        defaultNodeShape: 0,
        defaultNodeBorderWidth: 0,
        defaultLineWidth: 1,
        allowAutoLayoutIfSupport: false,
        allowShowRefreshButton: false,
        allowShowDownloadButton: false,
        layouts: [
          {
            force_line_elastic: 0.1,
            force_node_repulsion: 0.1,
            layoutName: 'force',
            layoutClassName: 'seeks-layout-center',
            defaultExpandHolderPosition: 'hide',
            defaultJunctionPoint: 'border',
            defaultNodeShape: 1,
            defaultLineShape: 1,
            min_per_width: '200',
            min_per_height: '300'
          }
        ]
      },

      isAddId: []
    }
  },

  props: {
    moduleDetail: {
      type: [Array, Object],
      default: () => {
        return []
      }
    },
    otherParams: {
      type: [Array, Object],
      default: () => {
        return {}
      }
    },
    packageId: {
      type: [String, Number],
      default: 0
    }
  },

  created() {
    this.onNodeClick = throttle(
      (data, event) => {
        this._onNodeClick(data, event)
      },
      200,
      { trailing: false }
    )
  },

  mounted() {
    this.$nextTick(() => {
      this.showSeeksGraph(this.moduleDetail)
    })
  },

  computed: {
    isJump() {
      const perList = this.$store.state.permission.permission
      return perList.includes('系统管理-非菜单权限-企业详情页-跳转')
    }
  },

  methods: {
    onMouseMove($event) {
      const graphInstance = this.$refs.seeksRelationGraph.getInstance()
      const node = graphInstance.isNode($event.target)
      // console.log('onMouseMove:', $event.x, $event.y, node)
      if (node) {
        this.showNodeTips($event, node)
        this.isShowNodeTips = true
      } else {
        this.isShowNodeTips = false
      }
    },

    showNodeTips($event, nodeObject) {
      const graphInstance = this.$refs.seeksRelationGraph.getInstance()
      const _base_position = graphInstance.options.fullscreen
        ? { x: 0, y: 0 }
        : graphInstance.getBoundingClientRect()
      // console.log(
      //   'showNodeTips:',
      //   $event.clientX,
      //   $event.clientY,
      //   nodeObject,
      //   _base_position.x,
      //   _base_position.y
      // )
      this.currentNode = nodeObject
      this.nodeTipsPosition.x = $event.clientX - _base_position.x + 10
      this.nodeTipsPosition.y = $event.clientY - _base_position.y + 10
    },

    jumpCompany(uuid) {
      const url = `/#/keydetail/keydetail?companyUuid=${uuid}&packageId=${this.packageId}&showCompanyNameList=false`
      this.isJump && window.open(url)
    },

    graspGoPay() {
      this.showDetail = false
    },

    showSeeksGraph(sourceData) {
      // 查找主节点
      this.graphLoad = true
      const mainId = sourceData?.rootId
      sourceData?.nodes?.forEach(i => {
        if (i.id === mainId) {
          i.color = '#43a2f1'
          i.borderColor = 'yellow'
        }
        Object.assign(i, {
          width: 40,
          height: 40,
          styleClass: 'node-point',
          data: {
            cname: i.companyName,
            hot: i.hot,
            csp: i.csp
          }
        })
      })
      this.$refs.seeksRelationGraph.setJsonData(sourceData, graph => {
        graph.setZoom(60)
      })
      this.graphLoad = false
    },

    _onNodeClick(nodeObject, $event) {
      console.log('onNodeClick:', nodeObject)
      const uuid = nodeObject.id
      if (uuid !== this.moduleDetail.rootId && !this.isAddId.includes(uuid)) {
        const params = {
          module: 'productRelationGraph',
          packageId: this.packageId,
          companyUuid: uuid
        }
        getComDetailModel(params).then(res => {
          if (res.code === 60000 && res.data) {
            // 追加数据
            let data = res.data['产业链关系图']
            data.rootId = this.moduleDetail.rootId
            data.nodes?.forEach(i => {
              i.data = {
                cname: i.companyName,
                hot: i.hot,
                csp: i.csp
              }
              i.height = 40
              i.width = 40
            })
            this.addData(data, uuid)
          }
        })
      }
    },

    addData(data, uuid) {
      if (this.isAddId.includes(uuid)) return
      this.isAddId.push(uuid)
      this.$refs.seeksRelationGraph.appendJsonData(data, () => {
        // 修改二级节点颜色
        this.$refs.seeksRelationGraph.getNodeById(uuid).color = '#43a2f1'
        // 刷新节点
        this.$refs.seeksRelationGraph.refresh()
      })
    }
  }
}
</script>

<style lang="scss">
.productRelationGraph-main {
  .main-graph {
    height: 500px;
    width: 100%;
  }

  .node-point {
    line-height: 40px;
  }

  .node-class {
    color: forestgreen;
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: -55px;
    width: 100%;
    min-height: 25px;
    height: auto;
    /*text-align: center;*/
    text-align: left;
    cursor: pointer;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .bg-filter {
    filter: blur(1.5px);
  }

  .touch-no-receive {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.75);
    z-index: 100;
  }

  .touch-no-icon {
    width: 150px;
    height: 40px;
    margin-top: 100px;
    position: relative;
    font-size: 14px;
    color: #8f8f8f;
  }

  .touch-no-icon::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 41px;
    height: 22px;
    top: -50px;
    left: 42px;
    background: url('../../assets/no-receive.png') no-repeat;
    background-size: 100% 100%;
  }

  .touch-no-cont {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-text {
    margin: 20px 0;
    font-size: 15px;
    padding-left: 2em;
    box-sizing: border-box;
  }

  .bottom-panel span {
    height: 13px;
    display: inline-block;
    margin: 0 15px;
    position: relative;
    color: #8a8a8a;
  }

  .bottom-panel span:before {
    content: '';
    display: inline-block;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: #41cabf;
    position: absolute;
    left: -15px;
    top: 0;
  }

  .current-company:before {
    background-color: #43a2f1 !important;
  }

  .current-customer:before {
    background-color: #e6a23c !important;
  }

  .current-boss:before {
    background-color: #41cabf !important;
  }

  .c-tips {
    z-index: 999;
    padding: 10px;
    position: absolute;
    border-radius: 10px;
    font-size: 14px;
    background-color: #333333;
    color: #ffffff;
    border: #eeeeee solid 1px;
    box-shadow: 0px 0px 8px #cccccc;
    & > div {
      line-height: 25px;
      padding-left: 10px;
      font-size: 12px;
    }
  }
}
</style>
