<template>
  <div v-if="showModule" ref="moduleitem">
    <div class="title" ref="computedComPic" id="computedComPic">
      {{ moduleParam.module_title }}
      <i
        class="maimialogo"
        @click="openmaimai"
        v-if="moduleParam.module_title == '触达信息' && false"
        v-dt-permission="'智能获客-推荐线索-脉脉触达'"
      ></i>
    </div>

    <div class="touch-cont-mang p-b-38" v-loading="refreshLoading">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in tabArr"
          :key="index + item"
          :label="item"
          :name="item"
        >
          <div class="touch-tab-cont">
            <component
              :key="item"
              :otherParams="otherParams"
              :cardType="cardType"
              :is="modulCont"
              :detail="detail"
              :moduleParam="moduleParam"
              @getComDetailModel="handleComDetailModel"
              :moduleDetail="moduleDetail"
              :packageId="packageId"
              ref="comModuleCont"
            ></component>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getComDetailModel } from '@/api/thread'
import { isObjNull } from '@/utils/validate'
import tableList from './tableList'
import faceplate from './faceplate'
import cardscroll from './cardscroll'
import cardtouch from './cardtouch'
import idctouch from '@/components/ModulePack/idctouch'
import Bus from '@/utils/bus'

export default {
  name: 'TouchModuleTab',
  props: {
    moduleParam: {
      type: Object,
      default: () => {}
    },
    detail: {
      type: Object,
      default: () => {}
    },
    packageId: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      tabArr: [],
      activeName: '',
      ObjectArr: {},
      moduleDetail: {},
      cardType: 'phone',
      showModule: true,
      otherParams: {},
      refreshLoading: false,
      testTime: null,
      tableList,
      faceplate,
      cardscroll,
      cardtouch,
      idctouch
    }
  },

  computed: {
    modulCont() {
      if (this.moduleParam.module_name === 'list') {
        return this.tableList
      }
      if (this.moduleParam.module_name === 'panel') {
        return this.faceplate
      }

      if (this.moduleParam.module_name === 'cardscroll') {
        return this.cardscroll
      }
      if (this.moduleParam.module_name === 'cardtouch') {
        return this.cardtouch
      }
      if (this.moduleParam.module_name === 'idctouch') {
        return this.idctouch
      }
    }
  },

  mounted() {
    // 必须确保on事件在emit之前存在  否则接受不到事件
    this.$nextTick(() => {
      this.handleComDetailModel()
    })
  },

  watch: {
    moduleParam: {
      handler(val) {
        if (val.isReceive) {
          this.handleComDetailModel()
        }

        console.log(val, this.moduleDetail, 'appDetail')
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {
    Bus.$off('refreshModule' + this.moduleParam.module_api)
    // this.$refs.computedComPic.$el.innerHTML = ''
  },
  updated() {
    this.ModuleDom()
  },
  methods: {
    changeModuleDetail(val) {
      this.moduleDetail = val
    },
    openmaimai() {
      window.open(
        'https://maimai.cn/web/search_center?type=contact&query=' +
          this.detail.company_name +
          '&highlight=true'
      )
    },
    dataParam() {
      this.otherParams.pageSize = this.moduleParam.module_pageSize
      this.otherParams.isScroll = this.moduleParam.module_scroll === 1
      this.otherParams.isReceive = this.moduleParam.isReceive
      this.otherParams.shade = this.moduleParam.module_shade
    },
    handleClick(val) {
      Object.assign(this.moduleDetail, this.ObjectArr[val.name], {
        branch: val.name
      })

      console.log(this.moduleDetail, val, this.activeName, 7788)
      const index = Number(val.index)
      const _this = this
      // 判断 卡片判断logo
      if (_this.moduleParam.module_name === 'cardscroll') {
        for (var item in _this.moduleDetail.columns) {
          if (_this.moduleDetail.columns[item].attr) {
            if (
              _this.moduleDetail.columns[item].attr.split(',').indexOf('logo') >
              -1
            ) {
              _this.cardType = 'logo'
              break
            }
            _this.cardType = ''
          } else {
            _this.cardType = ''
          }
        }
      }
      if (_this.moduleParam.module_name === 'cardtouch') {
        if (val.name === '电话') {
          _this.cardType = 'phone'
        }
        if (val.name === '邮箱') {
          _this.cardType = 'email'
        }
        if (val.name === '地址') {
          _this.cardType = 'address'
        }
        if (val.name === '人脉') {
          _this.cardType = 'contact'
        }
      } else {
        _this.cardType = ''
      }
      setTimeout(() => {
        _this.$refs.comModuleCont[index].init()
        if (_this.moduleParam.module_name === 'cardscroll') {
          _this.windowWith()
        }
      }, 100)
    },
    handleComDetailModel(val) {
      this.dataParam()
      const params = {
        module: this.moduleParam.module_api,
        packageId: this.moduleParam.packageId,
        companyUuid: this.moduleParam.companyUuid
      }
      if (val) {
        this.refreshLoading = true
      }
      const _this = this
      if (!this.moduleParam.module_api) return
      getComDetailModel(params).then(res => {
        this.refreshLoading = false
        if (res.code === 60000) {
          if (isObjNull(res.data)) {
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                modulePackList: [
                  ...this.$store.state.plugin.modulePackList,
                  this.moduleParam
                ]
              }
            })
            console.log(res, 'ry')
            _this.moduleDetail = {}
            _this.showModule = true
            _this.ObjectArr = res.data
            // const tab = Object.keys(_this.ObjectArr)
            const tab = []
            for (var i in _this.ObjectArr) {
              console.log(i, 'i123')
              tab.push(i)
            }
            _this.tabArr = [...tab]
            _this.activeName = tab[0]
            Object.assign(_this.moduleDetail, {
              branch: _this.activeName
            })
            console.log(_this.moduleDetail, 9966)
            _this.$store.commit('update', {
              target: this,
              data: {
                moduleDetail: Object.assign(
                  _this.moduleDetail,
                  {},
                  _this.ObjectArr[_this.activeName]
                )
              }
            })
          } else {
            _this.showModule = false
            _this.moduleDetail = {}
            this.$store.commit('update', {
              target: this.$store.state.plugin,
              data: {
                modulePackList: this.$store.state.plugin.modulePackList.filter(
                  item => item.module_title !== this.moduleParam.module_title
                )
              }
            })
            return
          }

          // 判断 卡片判断logo
          if (_this.moduleParam.module_name === 'cardscroll') {
            for (var item in _this.moduleDetail.columns) {
              if (_this.moduleDetail.columns[item].attr) {
                if (
                  _this.moduleDetail.columns[item].attr
                    .split(',')
                    .indexOf('logo') > -1
                ) {
                  _this.cardType = 'logo'
                  break
                }
                _this.cardType = ''
              } else {
                _this.cardType = ''
              }
            }
          }
          if (this.moduleParam.module_name === 'cardtouch') {
            for (var item in _this.moduleDetail.columns) {
              if (_this.moduleDetail.columns[item].attr) {
                if (
                  _this.moduleDetail.columns[item].attr
                    .split(',')
                    .indexOf('phone') > -1
                ) {
                  _this.cardType = 'phone'
                  break
                }
                if (
                  _this.moduleDetail.columns[item].attr
                    .split(',')
                    .indexOf('email') > -1
                ) {
                  _this.cardType = 'email'
                  break
                }
                if (
                  _this.moduleDetail.columns[item].attr
                    .split(',')
                    .indexOf('address') > -1
                ) {
                  _this.cardType = 'address'
                  break
                }
                if (
                  _this.moduleDetail.columns[item].attr
                    .split(',')
                    .indexOf('maiMaiPersonName') > -1
                ) {
                  _this.cardType = 'contact'
                  break
                }
                _this.cardType = ''
              } else {
                _this.cardType = ''
              }
            }
          }
          // setTimeout(() => {
          //     _this.ModuleDom()
          // }, 100)
        }
      })
    },
    ModuleDom() {
      console.log(this.$refs.comModuleCont)
      if (this.$refs.comModuleCont) {
        this.$refs.comModuleCont[0].init()
        if (this.moduleParam.module_name === 'cardscroll') {
          this.windowWith()
        }
      }
    },
    windowWith() {
      if (this.moduleParam.module_name === 'cardscroll') {
        const CCP = document.getElementById('computedComPic')
        const currentNum = Math.floor((CCP.clientWidth - 180) / 250)
        this.$refs.comModuleCont[
          this.tabArr.indexOf(this.activeName)
        ].jianTingWidth(currentNum)
        const _this = this
        window.onresize = function() {
          if (CCP) {
            if (CCP.clientWidth >= 1100) {
              const CCP = document.getElementById('computedComPic')
              const Num = Math.floor((CCP.clientWidth - 180) / 250)
              _this.$refs.comModuleCont[
                _this.tabArr.indexOf(_this.activeName)
              ].jianTingWidth(Num)
            }
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.title {
  width: 100%;
  height: 59px;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #2e2e2e;
  line-height: 59px;
  padding-left: 21px;
  padding-right: 36px;
}

.card-name-cont {
  display: inline-block;
  width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custo-phone-show {
  display: inline-block;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.phone-card-list ::v-deep .el-checkbox__label {
  display: none;
}

.phone-card-list ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #41cabf;
  border-color: #41cabf;
}

.phone-card-list ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #41cabf;
}

.phone-card-list ::v-deep .el-checkbox__inner:hover {
  border-color: #78d9d1;
}

.phone-card-list p {
  display: inline-block;
  font-size: 12px;
  line-height: 17px;
  width: 220px;
}

.maimialogo {
  position: relative;
  top: 6px;
  left: 10px;
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url('../../assets/maimai_logo.png') no-repeat center;
  background-size: 24px 24px;
  cursor: pointer;
}

.card-tag-name {
  display: inline-block;
  height: 17px;
  background: #ebfaf9;
  padding: 0 10px;
  color: #41cabf;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-left: 12px;
  vertical-align: top;
}

.card-f14-txt {
  font-size: 14px;
  color: #41cabf;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  margin-left: 6px;
}

.card-phone-name {
  font-size: 12px;
  color: #2e2e2e;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  vertical-align: top;
}

.card-cont-txt {
  height: 35px;
  width: 100%;
}

.card-cont-txt ::v-deep .el-checkbox {
  margin-right: 6px;
}

.touch-phone-card {
  padding: 2px 0 0;
  height: 100%;
}

.phone-card-list {
  display: inline-block;
  height: 80px;
  width: 220px;
  margin: 5px 5px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.11);
  cursor: pointer;
  position: relative;
  padding-left: 10px;
}

.touch-card-title {
  font-weight: 400;
}

.touch-tab-cont {
  width: 100%;
  min-height: 120px;
  padding: 0 21px;
  position: relative;
}

.touch-no-receive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
  opacity: 0.9;
  z-index: 100;
}

.touch-no-icon {
  width: 150px;
  height: 40px;
  margin-top: 100px;
  position: relative;
  font-size: 14px;
  color: #8f8f8f;
}

.touch-no-icon::before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 41px;
  height: 22px;
  top: -50px;
  left: 42px;
  background: url('../../assets/no-receive.png') no-repeat;
  background-size: 100% 100%;
}

.touch-no-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.touch-cont-mang {
  width: 100%;
  /*min-width: 1200px;*/
  background-color: #ffffff;
  margin-top: 4px;
}

.title {
  width: 100%;
  height: 59px;
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: #2e2e2e;
  line-height: 59px;
  padding-left: 21px;
  padding-right: 36px;
}

/*.touch-cont-mang ::v-deep .el-tabs__item {*/
/*  border: none;*/
/*  height: 50px;*/
/*  line-height: 50px;*/
/*  font-size: 16px;*/
/*  width: 180px;*/
/*  text-align: center;*/
/*  color: #8A8A8A;*/
/*  border-bottom: 1px solid #E6E6E6;*/
/*  border-top: 1px solid #E6E6E6;*/
/*}*/

/*.touch-cont-mang ::v-deep .el-tabs__item.is-active {*/
/*  color: #41CABF;*/
/*  background: white;*/
/*  border-bottom: 1px solid white;*/
/*  border-left: 1px solid #E6E6E6;*/
/*  border-right: 1px solid #E6E6E6;*/
/*  border-top: 1px solid #41CABF;*/
/*  width: 180px;*/
/*}*/

/*.touch-cont-mang ::v-deep .el-tabs__item:first-child {*/
/*  border-left: none;*/
/*}*/

/*.touch-cont-mang ::v-deep .el-tabs__item:last-child::after {*/
/*  content: '';*/
/*  width: 2000px;*/
/*  height: 1px;*/
/*  background: #E6E6E6;*/
/*  top: -1px;*/
/*  left: 180px;*/
/*  position: absolute;*/
/*  display: inline-block;*/
/*}*/

/*.touch-cont-mang ::v-deep .el-tabs__item:last-child::before {*/
/*  content: '';*/
/*  width: 2000px;*/
/*  height: 1px;*/
/*  background: #E6E6E6;*/
/*  bottom: -1px;*/
/*  left: 180px;*/
/*  position: absolute;*/
/*  display: inline-block;*/
/*}*/

/*.touch-cont-mang ::v-deep .el-tabs__item:hover {*/
/*  color: #41CABF;*/
/*}*/

/*.touch-cont-mang ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {*/
/*  border-radius: 0;*/
/*  border: none;*/
/*}*/

/*.touch-cont-mang ::v-deep .el-tabs__nav-scroll {*/
/*  background: #FCFCFC;*/

/*  height: 50px;*/
/*}*/
.touch-cont-mang ::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}

.touch-cont-mang ::v-deep .el-tabs__nav-wrap {
  margin-left: 20px;
}

.touch-cont-mang ::v-deep .el-tabs__item {
  font-size: 14px;
}

.touch-cont-mang ::v-deep .el-tabs__nav {
  width: 100%;
}

.touch-cont-mang ::v-deep .el-tabs__item {
  text-align: center;
  width: 25%;
}
</style>
