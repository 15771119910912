<template>
  <div class="packageKeyList-main">
    <div class="search">
      <div class="input">
        <el-input
          clearable
          size="small"
          v-model="word"
          style="width: 180px"
          placeholder="请输入公司名称查询"
        ></el-input>
      </div>
      <div class="btn">
        <el-button type="primary" size="small" @click="getList">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </div>
      <div
        type="text"
        class="filter"
        @click="openSearch"
        v-loading="filterLoad"
      >
        高级筛选
      </div>
      <!-- <div v-if="condition" class="filter-result">
        <span>筛选条件</span>
        <div>{{ `(${this.condition})` }}</div>
      </div> -->
      <el-button
        @click="handleExport"
        size="small"
        v-dt-permission="'企业画像-企业画像-导出企业明细'"
        :loading="btnLoad"
        type="primary"
        class="export"
        >导出</el-button
      >
    </div>
    <pe-table
      :peColumnList="columns"
      :peTableData="list"
      :peLoading="loading"
      :pePage="pageObj"
      :current-column-width="{ company_name: 300 }"
      @sortPeChange="sortChange"
      @handleCurrentPePage="handleCurrentChange"
      @handleSizePeChange="changePageSize"
    >
      <template v-slot:company_name="{ scope }">
        <span class="c-name" @click="goKeyDetail(scope.data, scope.index)">
          {{ scope.data.company_name }}
        </span>
        <Tags :tagList="scope.data.tags" />
      </template>
    </pe-table>
  </div>
</template>

<script>
import {
  package_detail_condition,
  getCoditionPage,
  package_filter
} from '@/api/thread'

import { getQyDetailExport } from '@/api/module'
import footerPage from '@/components/Footerpage/oace'
import searchBreed from '@/views/extendApp/search/searchPanel'
import { enterCompanyDetail, exportToExcel } from '@/utils/helper'
import PeSelect from '@/components/Peculiar/select'
import PeTable from '@/components/Peculiar/table'
export default {
  name: 'packageKeyList',

  components: {
    PeTable,
    footerPage,
    PeSelect
  },

  data() {
    return {
      filterLoad: false,
      btnLoad: false,
      pageObj: {
        pageNo: 1,
        pageSize: 20,
        total: 0
      },
      list: [],
      columns: [],
      loading: false,

      word: '',
      condition: '',
      tag: '',
      sort: 'quality_score:降序',
      show: 0,
      websiteAccess: 0,
      isJob: 0,
      phone: 0,
      upGongyingshang: 0,
      newClues: 0,
      customer: 0,
      followUserId: '',
      qualityCustomer: 0,
      whetherClick: 0,
      consumption: 0,
      unCalled: false,
      readOnlyExcept: false
    }
  },

  props: {
    package: {
      type: Object,
      default: () => {}
    },
    minHeight: {
      type: Number,
      default: 0
    }
  },

  watch: {
    'package.id': function () {
      this.getList()
    }
  },

  mounted() {
    this.pageObj.pageSize = 20
    this.getList()
  },

  methods: {
    async handleExport() {
      this.btnLoad = true
      const { data } = await getQyDetailExport({
        packageId: this.package.id,
        condition: this.condition,
        keywords: this.word
      })
      this.btnLoad = false
      const url = data.ossPath
      const link = document.createElement('a')
      link.href = url
      // 如果需要指定下载文件名
      // link.download = 'desired-filename.pdf';
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      console.log(data)
      // exportToExcel(this.peTableData)
    },

    reset() {
      this.list = []
      this.pageObj.pageNo = 1
      this.pageObj.total = 0
      this.word = ''
      this.condition = ''
      this.tag = ''
      this.sort = ''
      this.show = 0
      this.websiteAccess = 0
      this.isJob = 0
      this.phone = 0
      this.upGongyingshang = 0
      this.newClues = 0
      this.customer = 0
      this.followUserId = ''
      this.qualityCustomer = 0
      this.whetherClick = 0
      this.consumption = 0
      this.unCalled = false
      this.readOnlyExcept = false
      this.$nextTick(() => {
        this.getList()
      })
    },
    getList() {
      const params = {
        packageId: this.package.id,
        pageNo: this.pageObj.pageNo,
        pageSize: this.pageObj.pageSize,
        companyName: this.word,
        condition: this.condition,
        tag: this.tag,
        sort: this.sort,
        show: this.show,
        websiteAccess: this.websiteAccess,
        isJob: this.isJob,
        phone: this.phone,
        upGongyingshang: this.upGongyingshang,
        newClues: this.newClues,
        customer: this.customer,
        followUserId: this.followUserId,
        qualityCustomer: this.qualityCustomer,
        whetherClick: this.whetherClick,
        consumption: this.consumption,
        unCalled: this.unCalled,
        readOnlyExcept: this.readOnlyExcept,
        filterDisplayCols: 'follow_name'
      }
      this.loading = true
      getCoditionPage(params).then(res => {
        if (res.code === 60000) {
          this.pageObj.total = parseInt(res?.data?.total || '0')
          package_detail_condition(params).then(r => {
            this.loading = false
            if (r.code === 60000) {
              this.list = r?.data?.list || []
              this.columns = r?.data?.columns || []
            }
          })
        } else {
          this.loading = false
        }
      })
    },
    handleCurrentChange(val) {
      this.pageObj.pageNo = val
      this.getList()
    },
    goNumPage(val) {
      this.pageObj.pageNo = Number(val)
      this.getList()
    },
    changePageSize(val) {
      this.pageObj.pageSize = val
      const N = Math.ceil(this.pageObj.total / this.pageObj.pageSize)
      if (Number(this.pageObj.pageNo) > N) {
        this.pageObj.pageNo = N
      }
      this.getList()
    },
    sortChange(data, prop) {
      const map = { ascending: '升序', descending: '降序' }
      this.sort = `${prop}:${map[data.order]}`
      this.getList()
      console.log(data)
    },
    openSearch() {
      this.filterLoad = true
      package_filter({ packageId: this.package.id }).then(res => {
        if (res.code === 60000) {
          this.filterLoad = false
          this.$store.dispatch('showModal', {
            title: '高级筛选',
            view: searchBreed,
            size: '800px',
            options: {
              source: res.data,
              type: 'customerView'
            },
            onClose: res => {
              if (res.refresh) {
                this.pageObj.pageNo = 1
                this.pageObj.pageSize = 20
                this.condition = res.searchFilter
                this.getList()
              }
            }
          })
        }
      })
    },
    goKeyDetail(data, index) {
      enterCompanyDetail(data, this.list, index, 0, {
        followUserId: data.user_id
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/variables.scss';
.packageKeyList-main {
  padding: 0 10px;
  box-sizing: border-box;
  .search {
    height: 50px;
    display: flex;
    align-items: center;
    position: relative;
    padding-bottom: 10px;
    box-sizing: border-box;
    &::after {
      content: '';
      display: inline-block;
      height: 1px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      transform: scaleY(0.5);
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .input,
    .btn {
      margin-left: 15px;
      margin-right: 15px;
    }
    .input {
      width: 200px;
    }
    .filter {
      color: $main-color;
      font-size: 14px;
      text-decoration: underline;
      font-weight: 400;
      cursor: pointer;
      display: flex;
    }
  }

  .table {
    .c-name {
      color: #0099cc;
      cursor: pointer;
    }
  }

  .export {
    position: absolute;
    right: 10px;
  }

  .filter-result {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 10px;
  }
}
</style>
<style lang="scss">
.packageKeyList-main {
  .list-table-app {
    .el-table--enable-row-transition .el-table__body tbody tr td {
      border-right: none;
    }
    .el-table--border .el-table__body tbody tr td {
      border-right: none;
    }
  }
}
</style>
