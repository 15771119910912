<template>
  <div>
    <div style="height: calc(100vh)">
      <RelationGraph
        v-loading="graphLoad"
        ref="graphRef"
        :options="hTreeOptions"
      >
      </RelationGraph>
    </div>
  </div>
</template>

<script>
import RelationGraph from 'relation-graph'
import { debounceFn } from '@/utils/helper'
export default {
  name: 'PeRelationGraph',

  props: {
    relationData: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    RelationGraph
  },

  data() {
    return {
      graphLoad: false,
      hTreeOptions: {
        layout: {
          label: '中心',
          layoutName: 'force',
          layoutClassName: 'seeks-layout-force'
        },
        graphOffset_y: -2000,
        graphOffset_x: 0,
        moveToCenterWhenRefresh: true,
        defaultNodeBorderWidth: 0,
        defaultLineColor: 'rgba(0, 186, 189, 1)',
        defaultNodeColor: 'rgba(0, 206, 209, 1)',
        reLayoutWhenExpandedOrCollapsed: false,
        defaultExpandHolderPosition: 'bottom',
        zoomToFitWhenRefresh: true, // UI
        useAnimationWhenExpanded: true,
        debug: false,
        disableZoom: true,
        useAnimationWhenRefresh: true,
        allowAutoLayoutIfSupport: false,
        allowShowRefreshButton: false,
        allowShowDownloadButton: false
      }
    }
  },

  watch: {
    relationData: {
      handler(data) {
        this.showTree(data)
      },
      immediate: true
    }
  },

  methods: {
    showTree: debounceFn(function () {
      const that = this
      this.graphLoad = true
      this.$refs.graphRef.setOptions(this.hTreeOptions)
      this.$refs.graphRef.setJsonData(this.relationData, graph => {
        // 这些写上当图谱初始化完成后需要执行的代码
        graph.setZoom(20)
        that.graphLoad = false
      })
    })
  }
}
</script>

<style scoped>
::v-deep .yd_center {
  display: block;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #0759a1 !important;
  font-size: 60px;
  color: #fff;
}

::v-deep .yd_hlwkj {
  display: block;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #2f73ff !important;
  font-size: 50px;
  color: #fff;
}

::v-deep .yd_znzz {
  display: block;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #37a2ff !important;
  font-size: 50px;
  color: #fff;
}

::v-deep .yd_ai {
  display: block;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #f52e5a !important;
  font-size: 50px;
  color: #fff;
}

::v-deep .yd_ai_link {
  display: block;
  color: #f52e5a;
}

::v-deep .yd_whcm {
  display: block;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #ffbf00 !important;
  font-size: 50px;
  color: #fff;
}

::v-deep .yd_yyyl {
  display: block;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: #f9859f !important;
  font-size: 50px;
  color: #fff;
}

::v-deep .relationNode {
  display: block;
  font-size: 35px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  color: #000;
  background: #9966ff !important;
}

::v-deep .relationNode2 {
  display: block;
  font-size: 40px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  color: #000;
  background: #9966ff !important;
}
</style>
