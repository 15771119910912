var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "risk-page" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeIndex,
            callback: function ($$v) {
              _vm.activeIndex = $$v
            },
            expression: "activeIndex",
          },
        },
        _vm._l(_vm.tabList, function (tab, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { name: tab.pid, label: tab.name },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("pe-form", { attrs: { formOpt: _vm.currentTab.filters } }),
      _vm._v(" "),
      _c("pe-table", {
        attrs: { "table-list": _vm.tableData, "table-column": _vm.tableColumn },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }