<template>
  <div class="flex-table">
    <div class="table-header">
      <div
        v-for="(item, index) in handleNewColumn"
        class="header-item"
        :style="handleHeaderWidth(item)"
        :key="index"
      >
        {{ item.label }}
      </div>
    </div>
    <div @click="handleItemClick">
      <vue-seamless-scroll
        :data="tableList"
        class="roll-table"
        :class-option="classOption"
      >
        <ul>
          <li
            v-for="(el, idx) in tableList"
            :key="idx"
            :style="`border-bottom: 1px solid ${el.border};`"
          >
            <div v-for="(it, ix) in tableColumn" :key="ix" class="col-item">
              <div class="table-dot-flex" :style="handleColumnWidth(el, it)">
                <span
                  v-if="ix == 0"
                  class="table-dot"
                  :style="`background:${el.background}`"
                ></span>

                <div
                  :class="handleTableClass(el, it)"
                  :data-type="handleTableType(el, it)"
                  :data-url="handleTableUrl(el, it)"
                  :data-item="handleTableAlert(el, it)"
                  :data-index="idx"
                >
                  {{ handleTableData(el, it) }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>

    <c-dialog
      :show="visible"
      :companyInfo="companyInfo"
      @handleClose="visible = false"
    >
    </c-dialog>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import CDialog from '@/views/charts/components/click-dialog'
import { enterCompanyDetail } from '@/utils/helper'
export default {
  name: 'FlexTable',

  data() {
    return {
      visible: false,
      show: false,
      disScroll: {
        autoPlay: false
      },
      companyInfo: {}
    }
  },

  computed: {
    handleNewColumn() {
      let tableType = {}
      const list = []
      this.tableColumn.forEach(col => {
        this.tableList.forEach(el => {
          if (el[col].type) {
            tableType = { label: col, value: el[col] }
          }
        })
      })
      this.tableColumn.forEach(col => {
        list.push({
          label: col,
          value: tableType.label == col ? tableType.value : null
        })
      })
      return list
    },

    handleNewTable() {
      let tableType = {}
      const list = []
      this.tableColumn.forEach(col => {
        this.tableList.forEach(el => {
          if (el[col].type) {
            tableType = { label: col, value: el[col] }
          }
        })
      })
      this.tableList.forEach(item => {
        const current = item[tableType.label]
        current.company_name = current.val
        list.push(current)
      })
      console.log(tableType, list, 'tableType')
      return list
    }
  },

  props: {
    tableList: {
      type: Array,
      default: () => []
    },

    tableColumn: {
      type: Array,
      default: () => []
    },

    classOption: {
      type: Object,
      default: () => ({ autoPlay: false })
    }
  },

  methods: {
    handleHeaderWidth(item) {
      const data = item.value
      const width = data?.width || '240px'
      return data ? `width:${width}` : 'width:auto;flex:1'
    },

    handleColumnWidth(item, name) {
      const data = item[name]
      const width = data?.width || '240px'
      return data.width ? `width:${width}` : 'width:auto;flex:1'
    },

    handleTableType(el, it) {
      return el[it]?.type
    },

    handleTableUrl(el, it) {
      return el[it]?.url
    },

    handleTableAlert(el, it) {
      return JSON.stringify(el[it]?.alert)
    },

    handleParams(str) {
      const list = str.split('=')
      return { [list[0]]: list[1] }
    },

    handleItemClick(e) {
      const value = e.target.dataset
      const { item, type, url, index } = value
      if (type == 'url' && url) {
        const pList = url.split('?')[1].split('&')
        const Obj = {}
        pList.forEach(str => {
          Object.assign(Obj, this.handleParams(str))
        })

        console.log(Obj, index, 'click')
        enterCompanyDetail(Obj, this.handleNewTable, index, 0)
        // window.open(url)
      }

      if (type == 'alert') {
        try {
          const data = JSON.parse(item)
          this.visible = true
          this.companyInfo = data
        } catch (err) {
          console.log(err)
        }
      }
    },

    handleTableData(item, type) {
      const value = item[type]?.val
      return value ? value : item[type]
    },

    handleTableClass(item, type) {
      // console.log(item, type, 'class')
      return item[type]?.type ? 'link' : ''
    }
  },

  components: {
    vueSeamlessScroll,
    CDialog
  }
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';
::v-deep .el-tag {
  margin: 5px;
  color: #fff;
  border-color: transparent;
  background: $main-color;
}

.flex-table {
  height: 100%;
  overflow: hidden;
  .table-dot-flex {
    display: flex;
    align-items: center;
    .table-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .dialog-content {
    line-height: 25px;
  }

  .card {
    color: #888;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 5px;
    margin-top: 5px;
  }

  .c-name {
    color: #2e2e2e;
    font-size: 18px;
    font-weight: 600;
  }

  .title {
    color: #2e2e2e;
    font-weight: 600;
    font-size: 14px;
  }

  ul {
    li {
      display: flex;
      font-size: 16px;
      color: #c1bfcc;
      height: 40px;
      line-height: 40px;
      .col-item {
        flex: 1;
      }
    }
  }

  .table-header {
    display: flex;
    align-items: center;
    .header-item {
      // flex: 1;
      padding: 10px 0;
      color: #c1bfcc;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .roll-table {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .link {
    color: $link-color;
    cursor: pointer;
  }
}
</style>
