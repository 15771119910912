<template>
  <div class="app-container">
    <div class="detail-wrap">
      <span class="key-title" @click="goBackPage()">{{ currentTitle }}</span>
      <span>></span>
      <span>详情</span>
    </div>
    <pe-swiper
      v-if="showCompanyNameList && showScroll"
      @refreshDetail="refreshDetail"
      :scrollList="scrollList"
      ref="swiper"
    />

    <div style="display: flex">
      <div :style="handleFlexStyle">
        <div class="new-cus-tomer clearfix detail-company">
          <div v-show="detail.jingpin" class="jing-pin">
            <span>禁止</span><span>呼叫</span>
          </div>
          <div class="company-name">
            <div
              class="company-list"
              v-dt-permission="'智能获客-推荐线索-加入培育'"
            >
              <svg-icon
                v-if="detail.sub == 'true'"
                icon-class="collect2"
                class="star"
                @click="del_collect(detail.company_uuid)"
              />
              <svg-icon
                v-else
                icon-class="collect1"
                class="star"
                @click="add_collect(detail.company_uuid, true)"
              />
            </div>
            <span
              @click="copyComName(detail.company_name)"
              @mouseover="copyHoverIn()"
              @mouseout="copyHoverOut()"
              class="pack-name-tip"
              >{{ detail.company_name }}</span
            >
            <span v-if="currentHover" class="hover-tip-icon">{{
              hoverText
            }}</span>
            <el-button
              v-if="!detail.isReceive"
              type="primary"
              size="small"
              style="margin-left: 10px"
              @click="lingqu"
              >领取</el-button
            >
            <p v-if="detail.product_name" class="company_brief">
              品牌简称:{{ detail.product_name }}
            </p>
            <p v-if="detail.vc_step" class="vc">{{ detail.vc_step }}</p>
            <p v-if="detail.company_status" :style="isStatus()">
              {{ detail.company_status }}
            </p>
            <!-- icon点击 -->
            <div v-for="(ele, index) in textTool" :key="index">
              <PeTool>
                <div slot="txt-content">{{ ele.content }}</div>
                <div slot="txt-btn">
                  <img
                    v-show="ele.show"
                    @click="ele.click"
                    :src="ele.src"
                    :class="ele.class"
                  />
                </div>
              </PeTool>
            </div>
            <span v-show="customerInfoMap.inPool" class="company_desc">{{
              customerInfoMap.desc
            }}</span>
          </div>
          <p class="brief-desc" v-if="detail.brand_slogan">
            {{ detail.brand_slogan }}
          </p>
          <div v-if="!isPrivate" class="flex-hx">
            <template v-for="(item, index) in hxList">
              <div v-if="item.show" class="flex-hx-item" :key="index">
                <p>{{ item.label }}</p>
                <div v-if="item.url">
                  <span class="h-it">{{ `${item.value} ` }}</span>
                  <span class="date">{{ item.date }}</span>
                  <span class="h-point" @click="handleDownload(item.url)">{{
                    item.txt
                  }}</span>
                </div>
                <span v-else-if="item.score" class="h-it">{{
                  `${item.value} ${item.score}`
                }}</span>

                <span
                  v-else-if="item.project"
                  style="display: flex;align-items: center;"
                >
                  <div class="h-it">{{ item.value }}</div>
                  <PeTool v-if="item.tip">
                    <div slot="txt-content">
                      <div>入驻项目包括:</div>
                      <div v-for="(el, idx) in handleProjectName" :key="idx">
                        {{ el }}
                      </div>
                    </div>
                    <el-tag
                      slot="txt-btn"
                      style="margin-left:10px;margin-top: 10px;"
                      size="mini"
                    >
                      共{{ item.count }}个...
                    </el-tag>
                  </PeTool>
                </span>

                <span
                  v-else-if="item.block"
                  style="display: flex;align-items: center;"
                >
                  <div class="h-it">{{ item.value }}</div>

                  <PeTool v-if="item.tip">
                    <div slot="txt-content">
                      <div>所属版块包括:</div>
                      <div v-for="(el, idx) in handleBlock" :key="idx">
                        {{ el }}
                      </div>
                    </div>
                    <el-tag
                      slot="txt-btn"
                      style="margin-left:10px;margin-top: 10px;"
                      size="mini"
                    >
                      共{{ item.count }}个...
                    </el-tag>
                  </PeTool>
                </span>

                <span class="h-it" v-else>{{ item.value }}</span>
              </div>
            </template>
          </div>

          <!-- 营收指数 热度  云服务商 活跃度 cdn-->
          <div
            class="desc-list"
            v-dt-permission="'系统管理-非菜单权限-企业详情页-显示卡片'"
          >
            <template v-for="(item, index) in descList">
              <div :key="index" v-if="item.show">
                <div class="left">
                  <svg-icon :icon-class="item.icon" class="icon"></svg-icon>
                </div>
                <div class="right">
                  <div class="cloud-add">
                    <span>{{ item.name }}</span>
                    <span
                      v-if="item.remain && isShowCloud"
                      class="remain"
                      @click="handleHistory"
                      >{{ item.remain }}</span
                    >
                  </div>

                  <PePopover>
                    <span slot="content"
                      >{{ item.name }}: {{ detail[item.key] }}</span
                    >
                    <div slot="txt" class="detail-desc">
                      <span v-if="item.transfer">{{ item.value }}</span>
                      <span v-else>{{ detail[item.key] }}</span>
                      <span v-if="showLogic(item)" class="index-desc"
                        >截止到:{{ item.dateValue }}</span
                      >
                    </div>
                  </PePopover>
                </div>
              </div>
            </template>
          </div>

          <!-- 标签分类 -->
          <div class="new-qy-tag">
            <template v-for="(it, index) in getTags">
              <div class="flex-tag" :key="index">
                <p class="tag-classify">{{ index }}</p>
                <div :class="index == '榜单标签' ? 'bandTag' : 'new-tag'">
                  <template>
                    <div class="other-wrapper">
                      <template v-for="(el, idx) in it">
                        <el-tag
                          :key="idx"
                          size="mini"
                          @click="handleBandDetail(el, index)"
                          class="other-tag"
                          >{{ el.tagName }}</el-tag
                        >
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>

          <!-- 公司详情 -->
          <div v-loading="loadingDetail">
            <div v-if="detail.website" class="company-item">
              <span class="h-title">公司网址</span>
              <span class="website" @click="toShop(detail.website)">
                {{ detail.website }}
              </span>
            </div>

            <div v-if="detail.introduce" class="company-item">
              <span class="h-title">简介</span>
              <span v-html="detail.introduce"></span>
            </div>

            <div v-if="detail.comment" class="company-item">
              <span class="h-title">备注</span>
              <div class="content">
                <div v-if="detail.comment" class="key-remark">
                  <el-tooltip
                    :visible-arrow="false"
                    popper-class="popper"
                    effect="dark"
                    :content="detail.comment"
                    placement="bottom-start"
                  >
                    <span class="detail-comment">{{ detail.comment }}</span>
                  </el-tooltip>
                  <i
                    class="el-icon-edit collect_remark_detail_new"
                    @click="editRemark(detail.comment)"
                    v-dt-permission="'客户培育-线索培育-培育线索-编辑'"
                  ></i>
                </div>
                <div
                  v-else
                  class="remark-button"
                  style="color: #0099cc; cursor: pointer; width: 50px"
                  v-dt-permission="'客户培育-线索培育-培育线索-编辑'"
                  @click="addRemark()"
                >
                  添加
                </div>
              </div>
            </div>

            <div v-if="!isShowRisk" class="key-detail-risk">
              <p>风险</p>
              <ul>
                <li v-if="detail.risk.unpromise">失信被执行人</li>
                <li v-if="detail.risk.reorganization">破产重组</li>
                <li v-if="detail.risk.limit_consumption">限制高消费</li>
                <li v-if="detail.risk.debetor">被执行人</li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Tab -->
        <template>
          <Tab :tabData="newTabData" @tabChange="tabChange" />
        </template>

        <template v-if="selectVersion == '企业信息'">
          <PeBase :detail="detail" />
          <module-para
            ref="Mparams"
            :moduleParams="moduleParams"
            :detail="detail"
            v-if="showModule"
          ></module-para>
        </template>

        <template v-if="selectVersion == '云行业'">
          <PeQy :companyDetail="detail" />
          <div class="map-title">算力节点监测</div>
          <PeMap :companyInfoUuid="detail.company_uuid" />
          <template v-if="isShowNetList">
            <div class="net-wrap">
              <span class="net-title">安全检测</span>
              <PeTable
                class="net-table"
                :peTableData="netList"
                :peColumnList="netColumn"
                :pePage="netPage"
                :peLoading="netLoading"
                @handleCurrentPePage="handleCurrentPePage"
                :peFooter="false"
              >
                <template v-slot:domain="{ scope }">
                  <span
                    class="company-name-click"
                    @click="handleOpen(scope.data)"
                    >{{ scope.data.domain }}</span
                  >
                </template>
                <template v-slot:access="{ scope }">
                  <div v-if="scope.data.access == 1">
                    <i class="el-icon-success" style="color: $main-color"></i>
                    <span>是</span>
                  </div>
                  <div v-if="scope.data.access == 0">
                    <i class="el-icon-error" style="color: #f00"></i>
                    <span>否</span>
                  </div>
                </template>
                <template v-slot:dataCheck="{ scope }">
                  <div v-if="scope.data.dataCheck == 1">
                    <i class="el-icon-success" style="color: $main-color"></i>
                    <span>已使用SSL证书</span>
                  </div>
                  <div v-if="scope.data.dataCheck == 0">
                    <i class="el-icon-warning" style="color: #f00"></i>
                    <span>未使用SSL证书</span>
                  </div>
                </template>
                <template v-slot:contentCheck="{ scope }">
                  <div v-if="scope.data.contentCheck == 1">
                    <i class="el-icon-success" style="color: $main-color"></i>
                    <span>正常</span>
                  </div>
                  <div v-if="scope.data.contentCheck == 0">
                    <i class="el-icon-warning" style="color: #f00"></i>
                    <span>存在违法内容,可能被劫持</span>
                  </div>
                </template>
              </PeTable>
            </div>
          </template>
          <module-para
            ref="Mparams"
            :moduleParams="recommendParams"
            :detail="detail"
            v-if="showModule"
          ></module-para>
          <extendApp :proDetail="detail" />
        </template>

        <template v-if="selectVersion == '连锁行业'">
          <PeQy :companyDetail="detail" />
          <PeStore :companyUuid="detail.company_uuid" />
        </template>

        <template v-if="selectVersion == '企业出海'">
          <PeQy :companyDetail="detail" />
          <PeOverSea :companyUuid="detail.company_uuid" />
        </template>
      </div>
      <div
        v-dt-permission="'系统管理-非菜单权限-企业详情页-右侧操作区'"
        class="right-content"
        style="width: 40%; margin-left: 8px; margin-top: 8px; line-height: 32px"
      >
        <div class="subbar">
          <div>
            <el-switch
              v-model="capture2"
              @change="handlePyCheck"
              active-text=" 每次领取线索弹窗确认"
            >
            </el-switch>
          </div>

          <div>
            <el-button
              size="mini"
              v-dt-permission="'智能获客-推荐线索-加入或移除监控'"
              style="margin-left: 10px"
              :type="isMonitored ? '' : 'primary'"
              @click="addMonitor(detail)"
            >
              {{ isMonitored ? '移除监控' : '加入监控' }}
            </el-button>
            <el-button
              type="primary"
              @click="trans_client"
              style="min-width: 80px; margin-left: 10px"
              size="mini"
              v-show="
                fromPath !== '/customerpond/customerpond' &&
                  !customerInfoMap.inPool
              "
              v-dt-permission="'智能获客-推荐线索-转成客户'"
              >转成客户
            </el-button>
            <el-button
              type="primary"
              @click="getCustomerReceivers('3')"
              style="min-width: 80px; margin-left: 10px"
              size="mini"
              :disabled="!customerInfoMap.showTransform"
              v-show="
                fromPath !== '/customerpond/customerpond' &&
                  customerInfoMap.inPool
              "
              >发送至
            </el-button>
            <el-button
              type="primary"
              @click="getCustomerReceivers(activeTabs)"
              style="min-width: 80px; margin-left: 10px"
              size="mini"
              :disabled="!customerInfoMap.showTransform"
              v-show="fromPath === '/customerpond/customerpond'"
              >发送至
            </el-button>
            <el-button
              @click="editCusPond()"
              size="mini"
              type="primary"
              style="min-width: 80px; margin-left: 10px"
              >编辑客户
            </el-button>
            <el-button
              v-loading="trans_btn_loading"
              type="primary"
              @click="trans_company()"
              size="mini"
              v-if="
                fromPath !== '/globalquery/globalquery' &&
                  fromPath !== '/globalquery/query' &&
                  fromPath !== '/globalquery/query2' &&
                  fromPath !== '/customerpond/customerpond'
              "
              v-dt-permission="'智能获客-推荐线索-转派线索'"
              >线索转派
            </el-button>
          </div>
        </div>
        <div class="subcon">
          <div v-dt-permission="'系统管理-非菜单权限-企业详情页-显示触达'">
            <touchModule
              v-if="showTouch"
              :moduleParams="moduleParams"
              :detail="detail"
              :packageId="detail.packageId"
              :key="refreshTouch"
            ></touchModule>
            <template v-else>
              <p>触达模块</p>
              <div class="vip-click">
                <el-button type="primary" @click="handleVip"
                  >开通会员 解锁更多功能</el-button
                >
              </div>
            </template>
          </div>
        </div>
        <div
          class="module1"
          style="
            width: 100%;
            min-height: 900px;
            margin-top: 8px;
            background: #fff;
          "
          v-dt-permission="'系统管理-非菜单权限-企业详情页-显示跟进记录'"
        >
          <pond-detail
            path="keydetail"
            :customerInfoMap="customerInfoMap"
            svg
          ></pond-detail>
        </div>
      </div>
    </div>
    <!-- 添加备注 -->
    <div class="open_table evaluate-table">
      <el-dialog
        :visible.sync="dialogVisible"
        width="600px"
        top="25vh"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <div class="title" style="background-color: #41cabf; color: #fff">
          备注
        </div>
        <div class="content">
          <el-input
            type="textarea"
            :rows="5"
            style="width: 80%; margin-top: 20px"
            maxlength="250"
            placeholder="请输入内容(最多填写250个字符)"
            v-model="textarea"
          ></el-input>
        </div>
        <div class="footer">
          <el-button @click="submitAppraise">提交</el-button>
        </div>
      </el-dialog>
    </div>

    <dia-model ref="graspTip" :dialogStatus="graspData" v-if="showGrasp">
      <div slot="modalCont">
        <div class="show_grasp_text">
          <span>本月赠送条数剩余：{{ accEarnNum.mounth }} 条;</span>
          <br />
          <span>不限时条数: {{ accEarnNum.permanent }} 条;</span>
        </div>
      </div>
      <div slot="submit">
        <div class="grasp_go_pay">
          <span @click="graspGoPay('a')">充值</span>
        </div>
      </div>
    </dia-model>

    <dia-model ref="graspTipC" :dialogStatus="graspData" v-if="showGraspC">
      <div slot="modalCont">
        <div class="show_grasp_text">
          <span>本月赠送条数剩余：{{ accEarnNum.mounth }} 条;</span>
          <br />
          <span>不限时条数: {{ accEarnNum.permanent }} 条;</span>
          <br />
          <span style="color: red; display: inline-block; margin-top: 20px"
            >请联系主管理员充值</span
          >
        </div>
      </div>
      <div slot="submit">
        <div class="grasp_go_pay">
          <span @click="graspGoPay('c')">确定</span>
        </div>
      </div>
    </dia-model>

    <!-- 转成客户 -->
    <dia-model
      ref="transToCus"
      :dialogStatus="transToCusStatus"
      @submitContBtn="submitTransBtn"
    >
      <div slot="modalCont" v-loading="showTransResult">
        <div
          v-if="transToCusStatus['template']"
          style="margin: 20px 0 40px; padding-left: 30px"
        >
          <span>转至：</span>
          <el-select
            v-model="currentTransCusUserId"
            default-first-option
            filterable
            placeholder="选择客户接收人"
          >
            <el-option
              v-for="item in transCus"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="trans-to-cus" v-else>
          <p>请选择转客户模式：</p>
          <el-radio v-model="chooseTransType" label="1"
            >模式一：转到对应跟进人名下，无跟进人转至公海
          </el-radio>
          <el-radio v-model="chooseTransType" label="2"
            >模式二：全部转到自己名下
          </el-radio>
        </div>
      </div>
    </dia-model>

    <dia-model
      ref="sureSubTip"
      :dialogStatus="sureSubData"
      @subimtCancle="subimtCancle"
      @submitContBtn="submitContBtn"
      :on-close="subimtCancle"
      v-if="sureTip"
    >
      <div slot="modalCont">
        <div class="show_grasp_text">
          <span @click="handlePy" class="pySetting">设置默认培育池</span>
          <div style="margin: 30px 0">
            <div>
              <el-checkbox v-model="addCollectionOpenVal"> </el-checkbox>
              <span style="font-size: 12px">
                领取，并加入默认培育池【培育池名称】（提供导出功能）</span
              >
            </div>
          </div>
        </div>
      </div>
    </dia-model>

    <div class="show_com_cont">
      <el-dialog
        title
        :top="comContType"
        :visible.sync="showCompanyContant"
        width="464px"
        :modal="false"
      >
        <div class="show_phone_list">
          <span
            v-for="(item, index) in companyAllPhone"
            :key="index + 1"
            class="show_phone_name"
            >{{ item }}</span
          >
        </div>
      </el-dialog>
    </div>

    <div class="show_remark_cont">
      <el-dialog
        title
        :top="comContType"
        :visible.sync="showUserRemark"
        width="220px"
        style
        height="120px"
        :modal="false"
      >
        <div>
          <div class="show_remark_list">
            <span class="show_remark_name">{{ detail.comment }}</span>
            <p @click="reverseRemark" class="edit_remark_user">修改备注</p>
          </div>
        </div>
      </el-dialog>
    </div>

    <select-book
      @changeBookList="changeBookList"
      @sureSubmitCollect="sureSubmitCollect"
      :BookArr="bookArr"
      ref="selectBook"
    ></select-book>

    <dia-model
      ref="trans_send"
      :dialogStatus="sendStatus"
      v-if="showModel"
      @submitContBtn="transInfoToUserBtn"
    >
      <div slot="modalCont" style="padding-left: 30px; margin: 20px 0 40px">
        <span>转派给：</span>
        <el-select
          v-model="currentTransUserId"
          default-first-option
          filterable
          placeholder="选择线索接收人"
        >
          <el-option
            v-for="item in transUsers"
            :key="item.userId"
            :label="item.name"
            :value="item.userId"
          ></el-option>
        </el-select>
        <p class="tip-trans-tip" v-if="!(transUsers && transUsers.length)">
          <span>线索接收人未设置，请前往</span>
          <span
            @click="goSubPage"
            style="color: #78d9d1; text-decoration: underline; cursor: pointer"
            >子账户管理中心</span
          >
          <span>设置</span>
        </p>
      </div>
    </dia-model>

    <dia-model
      ref="trans_send1"
      :dialogStatus="sendStatus1"
      @submitContBtn="transInfoToUserBtn1"
    >
      <div slot="modalCont" style="padding-left: 30px; margin: 20px 0 40px">
        <el-form inline label-width="100px" size="small">
          <el-form-item label="发送给" style="width: 100%">
            <el-select
              style="width: 100%"
              v-model="currentTransUserId1"
              default-first-option
              filterable
              placeholder="选择线索接收人"
            >
              <el-option
                v-for="item in allSendUser"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="退回理由" v-if="currentTransUserId1" required>
            <el-select
              style="width: 100%"
              v-model="currentTransUserId1"
              default-first-option
              filterable
              placeholder="选择线索接收人"
            >
              <el-option
                v-for="item in allSendUser"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </dia-model>

    <user-profile
      v-if="userProState"
      ref="userPro"
      :customerInfo="customerInfo"
      :title="selectTitle"
    />

    <pe-dialog
      PeDialogTitle="行业图谱"
      :PeDialogShow="schemaVisible"
      :PeDialogShowSure="false"
      PeDialogWidth="480px"
      @handlePeDialogClose="schemaVisible = false"
    >
      <div class="key-detail-company">{{ detail.company_name }}</div>
      <el-button-group v-if="detail.tagItem" class="el-btn-group">
        <template v-for="item in detail.tagItem">
          <el-button @click="handleSchema(item)" icon="el-icon-s-data">{{
            item.tagName
          }}</el-button>
        </template>
      </el-button-group>
    </pe-dialog>

    <pe-draw :peDrawerShow="showDraw" @handleDrawClose="showDraw = false">
      <div v-loading="refreshIdcLoading">
        <div style="margin: 10px 0 0 10px">
          <span style="font-weight: bold; font-size: 18px">历史云服务商</span>
          <el-button
            icon="el-icon-refresh-right"
            type="plain"
            size="small"
            @click="handleRefreshIdc"
            >刷新</el-button
          >
        </div>
        <yunServiceHistoryList
          :service-data="yunServiceList"
          :showLimit="true"
          v-loading="loadingYunService"
        />
      </div>
    </pe-draw>
  </div>
</template>
<script>
import Tab from './tab'
import { getSystemConfig } from '@/api/system'

import {
  companyuuid,
  collection_add,
  collection_remove,
  collection_comment,
  getPackageModuleConfig,
  getComDetailModel,
  refreshIdc,
  pyToast,
  addMonitor,
  isMonitor,
  delMonitor
} from '@/api/thread'

import {
  getKeyNum,
  postConSum,
  getTransUserList,
  getTransInfoToUser
} from '@/api/subaccount'

import {
  postCusTransCus,
  getCustomerReceivers,
  postCusTransToOThers,
  maimaiAdd,
  linkedinAdd,
  getCustomerLeaders,
  getCusTagsDdic,
  getQyTagsDic,
  sendClueAdd,
  transformAdd,
  getCustomerInfoId,
  yunServiceHistory
} from '@/api/customerpond'

import { getFatherNode, getNetCheck, getLoginConfig } from '@/api/module'
import { getPversionPrice } from '@/api/pay'
import { ResetSystemConfig, ResetAuthUserMeal } from '@/utils/auth'
import { isObjNull } from '@/utils/validate'
import {
  formatRegex,
  permissionEditOrDelete,
  withOutAccess
} from '@/utils/helper'

import Bus from '@/utils/bus'
import diaModel from '@/components/Model'
import SelectBook from './selectBook'
import yunServiceHistoryList from '@/views/mykey/dialog/yunServiceHistoryList'
import ModulePara from './module'
import PhoneStrip from '@/components/PhoneStrip'
import PondDetail from '@/views/customerpond/pondDetail'
import extendApp from '@/views/mykey/dialog/extendApp'
import addRemark from '@/views/mykey/dialog/addRemark'
import createCustomer from '@/views/customerpond/dialog/createCustomer'
import userProfile from '@/views/customerpond/userProfile'
import contactList from '@/views/customerpond/dialog/contactList'
import sendKey from '@/views/mykey/dialog/sendKey'
import PeiYuList from '@/views/map/dialog/PeiYuList'
import followRecord from '@/components/element/followRecord'
import touchModule from '@/views/mykey/touchModule'
import keyNoGet from '@/views/mykey/addWaiHu/keyNoGet'
import filterWaiHu from '@/views/mykey/addWaiHu/filterWaiHu'
import Tip from '@/views/insight/tip'

import PeSwiper from '@/components/Peculiar/swiper'
import PeTable from '@/components/Peculiar/table'
import PePopover from '@/components/Peculiar/popover'
import PeTool from '@/components/Peculiar/tool-tip'
import PeDialog from '@/components/Peculiar/dialog'
import PeDraw from '@/components/Peculiar/drawer'
import PeMap from '@/components/Peculiar/map'
import PeBase from '@/components/Peculiar/version/base'
import PeStore from '@/components/Peculiar/store'
import PePro from '@/components/Peculiar/pro'
import PeQy from '@/components/Peculiar/qy-tags'
import PeOverSea from '@/components/Peculiar/over-sea'
export default {
  name: 'CompanyDetail',
  filters: {
    statusFilter(status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },

  data() {
    return {
      isPrivate: false,
      showDraw: false,
      loadingYunService: false,
      yunServiceList: [],
      netList: [],
      peNetPage: 1,
      netColumn: [],
      netPage: [],
      netLoading: false,
      routerPath: '',
      schemaVisible: false,
      selectVersion: '',
      addCollectionOpenVal: false,
      capture2: false,
      refreshTouch: 0,
      showCompanyNameList: true,
      transToCusStatus: {},
      showTransResult: false,
      chooseTransType: '',
      sendStatus: {},
      sendStatus1: {},
      showModel: false,
      currentTransUserId: '',
      currentTransUserId1: '',
      currentTransCusUserId: '', //转成客户
      transUsers: [],
      transCus: [],
      info: [1, 2, 3, 4],
      dialogVisible: false,
      showModule: false,
      detail: {},
      personnel_list: [],
      currentTitle: '',
      fromPath: '',
      showGrasp: false,
      showGraspC: false,
      graspData: {},
      accEarnNum: {},
      sureSubData: {},
      sureTip: false,
      textarea: '',
      tipGetPhone: false,
      requestParam: {},
      loadingDetail: false,
      showCompanyContant: false,
      showUserRemark: false,
      comContType: '15vh',
      companyAllPhone: [],
      bookArr: [],
      collectId: '',
      currentHover: null,
      hoverText: null,
      moduleParams: [],
      recommendParams: [],
      allSendUser: [],
      //线索详情新增客户字段
      allLeaders: [],
      allLevel: [],
      allSource: [],
      allStatus: [],
      allClue: [],
      allPerTags: [],
      allMessage: [],
      perAllPerTags: [],
      userProState: false,
      selectTitle: '',
      customerInfo: {},
      activeTabs: '',
      trans_btn_loading: false,
      idcData: [],
      idcLoading: false,
      refreshIdcLoading: false,
      isMonitored: false,
      scrollList: [],
      emitEvent: false //默认不发射事件
    }
  },

  created() {
    const _this = this
    Bus.$on('getContactType', () => {
      //代表这个事件能触发
      this.emitEvent = true
      _this.getContactType()
    })

    Bus.$on('userBuyMeal', function() {
      _this.goBuyProduct()
    })

    Bus.$on('payResultRe', function() {
      _this.getKeyNum()
    })

    this.$bus.$on('addWaiHu', params => {
      this.addWaiHu(params)
    })
  },

  components: {
    Tip,
    Tab,
    ModulePara,
    followRecord,
    touchModule,
    extendApp,
    yunServiceHistoryList,
    diaModel,
    SelectBook,
    userProfile,
    PeMap,
    PeTable,
    PeDialog,
    PeTool,
    PePopover,
    PeSwiper,
    PhoneStrip,
    PondDetail,
    PeStore,
    PePro,
    PeQy,
    PeDraw,
    PeBase,
    PeOverSea
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(to, from, 'before123')
      if (from.meta.title) {
        // vm.currentTitle = localStorage.runName
        localStorage.setItem('currentTitle', from.meta.title)
        // localStorage.setItem('fromPath', from.path)
        // vm.routerPath = from.path
        // vm.fromPath = from.path
        if (from.path === '/package') {
          vm.currentTitle = sessionStorage.getItem('packageName')
            ? sessionStorage.getItem('packageName')
            : from.meta.title
        } else if (from.path === '/run-in/list') {
          vm.currentTitle = localStorage.getItem('runName')
        } else {
          vm.currentTitle = from.meta.title
        }
      }
    })
  },

  mounted() {
    this.handleInitData()
    this.requestParam.packageId = this.$route.query.packageId
    this.requestParam.companyUuid = this.$route.query.companyUuid
    this.requestParam.followUserId = this.$route.query.followUserId
    this.activeTabs = this.$route.query.activeTabs
    const showCompany = this.$route.query.showCompanyNameList
    const showType = this.$route.query.showType
    if (showCompany == false || showCompany == 'false' || showType == 1) {
      this.showCompanyNameList = false
    }
    const returnName = this.$store.state.user.accountInfo.cloudOpenList[0]?.name
    this.selectVersion = returnName == '通用行业' ? '企业信息' : returnName
    this.currentTitle = localStorage.currentTitle
  },

  methods: {
    handleDownload(url) {
      const link = document.createElement('a')
      link.href = url
      // 如果需要指定下载文件名
      // link.download = 'desired-filename.pdf';
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    refreshDetail(id, uuid, cname, index) {
      console.log(id, uuid, cname, index, 'refresh1234')
      this.$router.replace(
        {
          path: '/keydetail/keydetail',
          query: {
            activeIndex: index,
            packageId: id,
            companyUuid: uuid
          }
        },
        () => {
          this.requestParam.packageId = this.$route.query.packageId
          this.requestParam.companyUuid = this.$route.query.companyUuid
          this.requestParam.companyName = this.$route.query.companyName
          this.moduleParams?.forEach(item => {
            item.packageId = id
            item.companyUuid = uuid
            item.companyName = cname
            return item
          })
          this.getNetStatus()
          this.getDetail()
        }
      )
    },

    getDetail(val, fn) {
      const index = this.$route.query.activeIndex
      const packageId = this.$route.query.packageId
      const companyUuid = this.$route.query.companyUuid
      // debugger
      if (val !== 'module') {
        Bus.$emit('startLoading', true)
      }
      this.loadingDetail = true
      const params = {
        packageId,
        companyUuid,
        pageId: 2001,
        customerSea: localStorage.beforePath === '/customerpond/customerpond', // 是否来自客户公海 影响业务简介返回形式,
        preview: localStorage.isPreview === 'true'
      }

      companyuuid(params)
        .then(res => {
          this.loadingDetail = false

          if (res.code === 60000) {
            this.checkMonitorStatus()
            this.detail = res.data

            const unit = Math.pow(10, 8)
            this.descList.forEach(item => {
              if (item.dateKey) {
                const newValue = this.detail[item.key]
                item.dateValue = this.detail[item.dateKey]
                item.transfer = newValue >= unit
                item.value = formatRegex((newValue / unit).toFixed(0)) + '亿'
                this.detail[item.key] = formatRegex(newValue)
              }
            })

            try {
              this.scrollList = JSON.parse(localStorage.scrollList)
              const index = this.$route.query.activeIndex
              this.$refs.swiper.swiper.activeIndex = index
              this.scrollList[index].company_name = res.data.company_name
              this.handlePackageModule()
            } catch (err) {
              console.log(err)
            }
            this.$store.commit('SET_CUSTOMERINFOMAP', res.data.customerInfoMap)
            if (res.data.staff && res.data.staff.length) {
              this.personnel_list = res.data.staff
            } else {
              this.personnel_list = []
            }
          } else {
            this.detail = {}
          }
          typeof fn === 'function' ? fn() : ''
        })
        .catch(() => {
          this.detail = {}
          typeof fn === 'function' ? fn() : ''
        })
    },

    async handleIsPrivate() {
      const { data } = await getLoginConfig()
      this.isPrivate = data.pageShowConfig
    },

    handleInitData() {
      const configNumList = ['1', '2', '3', '4', '7', '8']
      this.getKeyNum()
      this.getDetail('module')
      configNumList.forEach(item => {
        this.getCusTagsDdic(item)
      })
      this.getSystemData()
      this.getCustomerLeaders()
      this.getQyDic('5')
      this.getNetStatus()
      this.handleIsPrivate()
    },

    showLogic(item) {
      const notList = ['hot', 'aiHot', 'branch_count']
      if (notList.includes(item.key)) {
        return false
      }
      if (item.dateKey) {
        if (item.dateValue == 0 || item.dateValue == '-') {
          return false
        } else {
          return true
        }
      }
      return false
    },

    async getYunHistory() {
      this.loadingYunService = true
      yunServiceHistory({ companyUuid: this.detail.company_uuid }).then(res => {
        this.loadingYunService = false
        if (res.code === 60000) {
          this.yunServiceList = res.data
        }
      })
    },

    handleVip() {
      withOutAccess()
    },

    async handleBandDetail(it, name) {
      if (!this.isPrivate) {
        return
      }
      if (!this.isJump) {
        return
      }
      sessionStorage.setItem('bdUuid', it.tagUuid)
      if (name == '榜单标签') {
        this.$router.push({
          name: 'bandDetail',
          query: {
            bdUuid: it.tagUuid
          }
        })
      } else {
        const { data } = await getFatherNode({ tagId: it.id })
        console.log(it, 'father')
        this.$router.push({
          path: '/insight/schema/detail/1001',
          query: {
            parentName: data,
            companyName: this.detail.company_name,
            tagName: it.tagName,
            id: it.id
          }
        })
      }
    },

    handleHistory() {
      this.showDraw = true
      this.getYunHistory()
    },

    handleOpen(data) {
      const base = data.dataCheck == 1 ? 'https://' : 'http://'
      const url = base + data.domain
      window.open(url)
    },

    async getNetStatus() {
      const { data } = await getNetCheck({
        pageNo: this.peNetPage,
        companyUuid: this.$route.query.companyUuid
      })
      this.netList = data?.list
      this.netColumn = data?.columns
      this.netPage = data?.page
    },

    handleCurrentPePage(page) {
      this.peNetPage = page
      this.getNetStatus()
    },

    tabChange(val) {
      this.selectVersion = val
    },

    isStatus() {
      const flag = this.detail.hotColorFlag
      if (flag) {
        return `
          color: #039512;
          border: 1px solid #039512;
          font-size: 14px;
          margin: 0 10px;
          padding:0 4px
      `
      } else {
        return `
          color: #f00;
          border: 1px solid #f00;
          font-size: 14px;
          margin: 0 10px;
          padding:0 4px
      `
      }
    },

    handleSchema(item) {
      this.$router.push({
        name: 'schemaDetail',
        query: {
          id: item.id,
          companyUuid: this.detail.company_uuid,
          parentName: item.tagName,
          tagName: this.detail.company_name
        }
      })
    },

    getSystemData() {
      getSystemConfig().then(res => {
        this.capture2 = res.data.isShowSelectBook ? true : false
        this.$store.dispatch('setSysConfig', res.data)
      })
    },

    handlePy() {
      this.$router.push('/collection/collection')
    },

    async handlePyCheck() {
      const { data } = await pyToast({
        isShowSelectBook: this.capture2 ? 1 : 0,
        addCollectionOpenVal: this.addCollectionOpenVal ? 1 : 0
      })
      this.getSystemData()
    },

    async getQyDic(val) {
      const { data } = await getQyTagsDic({ type: val })
      this.allPerTags = data
    },

    jumpDomain() {
      const { href } = this.$router.resolve({
        path: '/app/domain',
        query: { domain: this.detail.website }
      })
      window.open(href, '_blank')
    },

    checkMonitorStatus() {
      const params = {
        companyUuid: this.requestParam.companyUuid
      }
      isMonitor(params).then(res => {
        if (res.code === 60000) {
          this.isMonitored = !!res.data
        }
      })
    },

    addMonitor(val) {
      if (this.isMonitored) {
        this.delMonitor(val)
        return
      }
      const { company_name, company_uuid } = val
      const params = {
        companyName: company_name,
        companyUuid: company_uuid,
        monitorQuota: 1
      }
      addMonitor(params).then(res => {
        if (res.code === 60000) {
          this.getDetail()
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
        }
      })
    },

    delMonitor(val) {
      const { company_uuid } = val
      const params = {
        companyUuid: company_uuid
      }
      delMonitor(params).then(res => {
        if (res.code === 60000) {
          this.getDetail()
          this.$store.dispatch('showTip', {
            text: res.msg,
            type: 'success'
          })
        }
      })
    },

    addWaiHu(params) {
      const { type, data } = params
      let isGet = false
      if (type === 'one') {
        this.filterWaiHu(false, 'one', data)
      }
      if (type === 'more') {
        if (!data.show) {
          this.$store.dispatch('showModal', {
            title: '提示',
            view: keyNoGet,
            size: '400px',
            onClose: res => {
              if (res.isNext) {
                const loading = this.$loading({
                  lock: true,
                  text: '正在导入',
                  spinner: 'el-icon-loading',
                  background: 'rgba(0, 0, 0, 0.7)'
                })
                const t = setTimeout(() => {
                  if (res.checked) isGet = true
                  this.filterWaiHu(isGet, 'more')
                  loading.close()
                  clearTimeout(t)
                }, 500)
              }
            }
          })
        } else {
          const loading = this.$loading({
            lock: true,
            text: '正在导入',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          const t = setTimeout(() => {
            this.filterWaiHu(false, 'more')
            loading.close()
            clearTimeout(t)
          }, 500)
        }
      }
    },

    filterWaiHu(isGet = false, type = 'one', params = {}) {
      this.$store.dispatch('showModal', {
        title: '提示',
        view: filterWaiHu,
        options: {
          consumption: isGet,
          companyUuid: this.$route.query.companyUuid,
          packageId: this.$route.query.packageId,
          type: type,
          phone: params.phone
        },
        size: '600px',
        onClose: () => {
          if (isGet) this.getDetail('module')
        }
      })
    },

    lingqu() {
      this.getContactType()
    },

    handleRefreshIdc() {
      if (!this.detail.company_name || this.detail.show === false) return
      const params = {
        companyName: this.detail.company_name,
        companyUuid: this.$route.query.companyUuid
      }
      this.refreshIdcLoading = true
      this.$store.dispatch('showTip', {
        text: '正在为您刷新云服务商信息',
        type: 'success'
      })
      refreshIdc(params).then(res => {
        this.refreshIdcLoading = false
        if (res.code === 60000 && res.data) {
          this.showIdcFun()
          this.getDetail()
          this.getYunHistory()
        }
      })
    },

    showIdcFun() {
      this.idcData = []
      const params = {
        module: 'idc',
        packageId: this.$route.query.packageId,
        companyUuid: this.$route.query.companyUuid
      }
      this.idcLoading = true
      getComDetailModel(params).then(res => {
        this.idcLoading = false
        if (res.code === 60000 && res.data && res.data['云供应商变更']) {
          const idcData = res.data['云供应商变更'].list
          const result = {}
          idcData.forEach(item => {
            result[item.timeAt]
              ? (result[item.timeAt] = [...result[item.timeAt], item])
              : (result[item.timeAt] = [item])
          })
          const _res = []
          for (let i in result) {
            _res.push({
              time: i,
              idcName: result[i][0]['gongyingshang'],
              follows: result[i]
            })
          }
          this.idcData = _res
        }
      })
    },

    getCustomerNew() {
      const dict = {
        1: '1',
        2: '3',
        3: '2',
        4: '4'
      }
      let cusPosition = this.customerInfoMap.isPool
        ? dict[parseInt(this.customerInfoMap.poolPosition)]
        : false
      this.$store.dispatch('showModal', {
        title: '选择联系人',
        view: contactList,
        size: '900px',
        type: 'drawr',
        options: {
          companyUuid: this.requestParam.companyUuid,
          packageId: this.requestParam.packageId,
          showSelect: false,
          isActiveTab: cusPosition,
          type: 'keyDetail'
        },
        onClose: res => {
          this.getDetail('module')
        }
      })
    },

    permissionEdit() {
      const type = this.detail.customerInfoMap.poolPosition
      if (type == 1) {
        const res = permissionEditOrDelete(
          this.detail.customerInfoMap.appCustomers,
          '客户公海',
          '编辑'
        )
        return res
      }
      if (type == 2) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-全公司的-编辑'
        )
      }
      if (type == 3) {
        return this.permissionList.includes(
          '客户管理CRM-客户公海-可跟进的-编辑'
        )
      }

      if (type == 4) {
        return this.permissionList.includes('客户管理CRM-客户公海-草稿箱-编辑')
      }
      return false
    },

    editCusPond() {
      const flag = this.permissionEdit() && this.detail.customerInfoMap?.visible
      if (flag) {
        this.currentDataId = this.detail.customerInfoMap.appCustomers.id
        const params = {
          id: this.currentDataId
        }
        getCustomerInfoId(params).then(res => {
          if (res.code === 60000) {
            this.editCusModel(
              Object.assign(res.data, {
                province: this.detail.customerInfoMap.appCustomers.province,
                city: this.detail.customerInfoMap.appCustomers.city
              })
            )
          }
        })
      }
    },

    editCusModel(params) {
      const formData = {
        cooperationUsers: params.cooperationUsers,
        userId: params.userId || '',
        companyName: params.companyName || '',
        remark: params.remark || '',
        gradeId: params.gradeId || '',
        fromId: params.fromId || '',
        stateId: params.stateId || '',
        clueGradeId: params.clueGradeId || '',
        tagsId: params.tagsId || '',
        customerId: params.id || '',
        province: params.province,
        city: params.city,
        saleRange: params.saleRange || '',
        saleModel: params.saleModel
          ? params.saleModel.split(',').map(i => parseInt(i))
          : []
      }
      let selectedTags = []
      let selectedPrivateTags = []
      if (params.perTagsId) {
        selectedTags = params.perTagsId
          .toString()
          .split(',')
          .map(item => Number(item))
      }
      if (params.privateTagsId) {
        selectedPrivateTags = params.privateTagsId
          .toString()
          .split(',')
          .map(item => Number(item))
      }

      this.$store.commit('update', {
        target: this.$store.state.app,
        data: {
          canScroll: false
        }
      })

      this.$store.dispatch('showModal', {
        title: params.companyName,
        size: '850px',
        top: '90px',
        view: createCustomer,
        options: {
          leaderList: this.allLeaders,
          userId: this.creatLeader,
          allSource: this.allSource,
          allClue: this.allClue,
          allLevel: this.allLevel,
          allMessage: this.allMessage,
          allStatus: this.allStatus,
          allPerTags: this.allPerTags,
          perAllPerTags: this.perAllPerTags,
          selectTags: selectedTags,
          selectedPrivateTags: selectedPrivateTags,
          formData: formData,
          activeTabs:
            this.detail.customerInfoMap.poolPosition == '3'
              ? '2'
              : this.detail.customerInfoMap.poolPosition == '2'
              ? '3'
              : this.detail.customerInfoMap.poolPosition,
          type: 'edit'
        },
        onClose: res => {
          this.$store.commit('update', {
            target: this.$store.state.app,
            data: {
              canScroll: true
            }
          })

          if (res.refresh) {
            // 刷新列表
            this.getDetail()
          }
        }
      })
    },

    openCustomerPic() {
      this.customerInfo = {
        companyName: this.detail.company_name
      }
      if (this.fromPath == '/customerpond/customerpond') {
        Object.assign(this.customerInfo, { pathType: 2 })
      }
      if (this.fromPath == 'package') {
        Object.assign(this.customerInfo, { pathType: 1 })
      }
      this.$nextTick(() => {
        this.userProState = true
        this.$nextTick(() => {
          this.$refs.userPro.init()
        })
      })
    },

    getCusTagsDdic(val) {
      const params = {
        type: val
      }
      getCusTagsDdic(params).then(res => {
        if (res.code === 60000) {
          if (val === '1') {
            this.allLevel = res.data
          }
          if (val === '2') {
            this.allSource = res.data
          }
          if (val === '3') {
            this.allStatus = res.data
          }
          if (val === '4') {
            this.allClue = res.data
          }
          if (val === '7') {
            this.allMessage = res.data
          }
          if (val === '8') {
            this.perAllPerTags = res.data
          }
        }
      })
    },

    getCustomerLeaders() {
      const params = {
        pageId: 2001
      }
      getCustomerLeaders(params).then(res => {
        if (res.code === 60000) {
          this.allLeaders = res.data
          let shift = [
            {
              text: '全部',
              value: '-2'
            }
          ]

          res.data.forEach(item => {
            const obj = {}
            obj.text = item.name
            obj.value = item.id
            if (item.id !== -1) {
              shift.push({
                text: item.name,
                value: item.id
              })
            }
          })
          this.followList = shift
        }
      })
    },

    jumpHuaWei(cname) {
      window.open(
        `https://www.huaweicloud.com/partners/search/?search=${cname}`
      )
    },

    jumpMaiMai(cname) {
      const params = {
        companyUuid: this.$route.query.companyUuid
      }
      maimaiAdd(params).then(res => {
        if (res.code === 60000) {
          const url =
            'https://maimai.cn/web/search_center?type=contact&query=' +
            cname +
            '&highlight=true'
          window.open(url)
        }
      })
    },

    jumpLingying(cname) {
      const params = {
        companyUuid: this.$route.query.companyUuid
      }
      linkedinAdd(params).then(res => {
        if (res.code === 60000) {
          window.open('https://www.linkedin.com/company/' + cname + '/people/')
        }
      })
    },

    jumpHuaWeiyun(cname) {
      window.open(
        'https://www.huaweicloud.com/partners/search/?search=' + cname
      )
    },

    getCustomerReceivers(type = 3) {
      console.log(type)
      const params = {
        type: type,
        pageId: 2001
      }
      getCustomerReceivers(params).then(res => {
        if (res.code === 60000) {
          this.allSendUser = res.data
          this.openSendUserModel()
        }
      })
    },

    openSendUserModel() {
      this.$store.dispatch('showModal', {
        title: '选择发送对象',
        options: {
          userList: this.allSendUser,
          type: 'keyDetail'
        },
        view: sendKey,
        onClose: res => {
          if (res.refresh) {
            this.getDetail()
          }
        }
      })
    },

    transInfoToUserBtn1() {
      if (!this.currentTransUserId1) {
        this.$message({
          message: '请选择接收人',
          type: 'warning'
        })
        return
      }
      this.postCusTransToOThers()
    },

    postCusTransToOThers() {
      const params = {
        ids: this.customerInfoMap.id,
        userId: this.currentTransUserId1
      }
      postCusTransToOThers(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.getDetail()
          this.$refs.trans_send1.subimtCancle()
        }
      })
    },

    submitTransBtn() {
      if (this.fromPath === 'package' && !this.chooseTransType) {
        this.$message({
          message: '请选择要转成客户的模式',
          type: 'warning'
        })
        return
      }
      let props = {}
      if (
        (this.fromPath === '/globalquery/globalquery' ||
          this.fromPath === '/globalquery/query' ||
          this.fromPath === '/globalquery/query2') &&
        this.transToCusStatus.template === 2
      ) {
        if (
          this.currentTransCusUserId === '' ||
          this.currentTransCusUserId === null ||
          this.currentTransCusUserId === -1
        ) {
          this.$message({
            message: '请选择要转至的账号',
            type: 'warning'
          })
          return
        }
        props.type = 1
        props.followUserIds = this.currentTransCusUserId
      }
      this.postCusTransCus(props)
    },

    postCusTransCus(props = {}) {
      this.showTransResult = true
      const packageId =
        this.fromPath === '/globalquery/globalquery' ||
        this.fromPath === '/globalquery/query' ||
        this.fromPath === '/globalquery/query2'
          ? 0
          : this.requestParam.packageId // 自主找客进来 packageId为0
      const params = {
        companyNames: this.detail.company_name,
        followUserIds: this.requestParam.followUserId
          ? this.requestParam.followUserId === '-'
            ? '-1'
            : this.requestParam.followUserId
          : '-1',
        packageId,
        type: this.chooseTransType
      }
      const merge = Object.assign(params, props)
      postCusTransCus(merge).then(res => {
        if (res.code === 60000) {
          this.showTransResult = false
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.$refs.transToCus.subimtCancle()
          this.getDetail()
        } else {
          this.showTransResult = false
        }
      })
    },

    goSubPage() {
      this.$router.push('/subaccount/subaccount')
    },

    transInfoToUserBtn() {
      if (!this.currentTransUserId) {
        this.$message({
          message: '请选择线索接收人',
          type: 'warning'
        })
        return
      }
      const params = {
        ids: this.requestParam.companyUuid,
        userId: this.currentTransUserId,
        packageId: this.requestParam.packageId
      }
      getTransInfoToUser(params).then(res => {
        if (res.code === 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.showModel = false
        }
      })
    },

    trans_client() {
      const params1 = {
        companyUuid: this.$route.query.companyUuid
      }
      transformAdd(params1).then(res => {})
      if (this.$store.getters.userInfo.isAdmin) {
        if (
          this.fromPath === '/globalquery/globalquery' ||
          this.fromPath === '/globalquery/query' ||
          this.fromPath === '/globalquery/query2'
        ) {
          const params = {
            type: 2,
            pageId: 2001
          }
          getCustomerReceivers(params).then(res => {
            if (res.code === 60000) {
              this.transCus = res.data
              this.openTransCusModel({ template: 2 })
            }
          })
        } else {
          this.openTransCusModel()
        }
      } else {
        let props = { type: 2 }
        this.postCusTransCus(props)
      }
    },

    openTransCusModel(transToCusStatus) {
      this.chooseTransType = ''
      this.showTransCont = true
      const diaObj = {
        dialogWidth: '550px',
        dialogTitle: '转成客户',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.transToCusStatus = Object.assign(diaObj, transToCusStatus)
      this.$nextTick(() => {
        this.$refs.transToCus.init()
      })
    },

    trans_company() {
      const params1 = {
        companyUuid: this.$route.query.companyUuid
      }
      sendClueAdd(params1).then(() => {})
      this.trans_btn_loading = true
      getTransUserList().then(res => {
        this.trans_btn_loading = false
        if (res.code === 60000) {
          this.transUsers = res.data.filter(item => item.name !== '全部')
          this.openTransModel()
        }
      })
    },

    openTransModel() {
      this.showModel = true
      this.currentTransUserId = ''
      this.sendStatus = {
        dialogWidth: '550px',
        dialogTitle: '转派线索',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.trans_send.init()
      })
    },

    handlePackageModule() {
      const params = {
        id: this.$route.query.packageId || 0
      }
      this.showModule = false
      getPackageModuleConfig(params).then(res => {
        if (res.code === 60000) {
          this.moduleParams = res.data?.filter(
            item => item.module_branch != '专业版'
          )
          this.recommendParams = res.data?.filter(
            item => item.module_branch == '专业版'
          )
          if (isObjNull(res.data)) {
            this.showModule = true
            this.moduleParams.forEach(item => {
              item.packageId = this.$route.query.packageId
              item.companyUuid = this.$route.query.companyUuid
              item.isReceive = this.detail.show
              item.companyName = this.detail.company_name
              return item
            })
            this.recommendParams.forEach(item => {
              item.packageId = this.$route.query.packageId
              item.companyUuid = this.$route.query.companyUuid
              item.isReceive = this.detail.show
              item.companyName = this.detail.company_name
              return item
            })
          }
          this.refreshTouch += 1
          this.$store.commit('update', {
            target: this.$store.state.plugin,
            data: {
              modulePackList: []
            }
          })
        }
      })
    },

    copyComName(val) {
      event.stopPropagation()
      var oInput = document.createElement('input')
      oInput.value = val
      document.body.appendChild(oInput)
      this.selectText(oInput)
      document.execCommand('Copy')
      oInput.className = 'oInput'
      oInput.style.display = 'none'
      this.hoverText = '复制成功!'
      setTimeout(() => {
        this.currentHover = null
      }, 500)
    },

    selectText(oInput) {
      if (oInput.createTextRange) {
        //ie
        oInput.select()
      } else {
        //firefox/chrome
        oInput.setSelectionRange(0, oInput.value.length)
        oInput.focus()
      }
    },

    copyHoverIn() {
      this.currentHover = 'true'
      this.hoverText = '点击复制'
    },

    copyHoverOut() {
      this.currentHover = null
    },

    getContactType() {
      // debugger
      this.userGraspPhone()
    },

    reverseRemark() {
      this.showUserRemark = false
      this.checkChangeMark = false
      this.textarea = this.detail.comment
      this.dialogVisible = true
      this.markRow = this.currentCheckMark
    },

    // 添加备注
    submitAppraise() {
      const params = {
        companyUuid: this.$route.query.companyUuid,
        comment: this.textarea
      }
      collection_comment(params).then(res => {
        this.dialogVisible = false
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.getDetail()
      })
    },

    handleClose() {
      this.dialogVisible = false
    },

    //移除培育
    del_collect() {
      const param = []
      param.push({
        packageId: this.$route.query.packageId,
        companyUuid: this.$route.query.companyUuid
      })
      const params = {
        packageIdAndcompanyuuid: JSON.stringify(param)
      }
      collection_remove(params).then(res => {
        if (res.code == 60000) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.getDetail()
        }
      })
    },

    getFavoritesList(val) {
      const haveDefault = this.$store.getters.sysConfig.isShowSelectBook || 0
      if (haveDefault) {
        this.$store.dispatch('showModal', {
          title: '选择培育池',
          view: PeiYuList,
          size: '650px',
          onClose: res => {
            if (!res.refresh) {
              return
            }
            const params = {
              companyUuids: this.$route.query.companyUuid,
              packageId: this.$route.query.packageId,
              favoriteId: res.favoriteObj.id,
              isShowSelectBook: res.isShowSelectBook ? 1 : 0
            }
            collection_add(params).then(res => {
              if (res.code === 60000) {
                ResetSystemConfig()
                this.$store.dispatch('showTip', {
                  text: res.msg,
                  type: 'success'
                })
                Bus.$emit('dt-stop-loading')
                if (this.tipGetPhone) {
                  this.getPversionPrice()
                } else {
                  this.$message({
                    message: '加入培育池成功',
                    type: 'success'
                  })
                }
                this.getDetail()
              }
            })
          }
        })
      } else {
        const params = {
          companyUuids: this.$route.query.companyUuid,
          packageId: this.$route.query.packageId,
          favoriteId: val,
          isShowSelectBook: haveDefault
        }
        collection_add(params).then(res => {
          if (res.code === 60000) {
            this.$store.dispatch('showTip', {
              text: res.msg,
              type: 'success'
            })

            Bus.$emit('dt-stop-loading')
            if (this.tipGetPhone) {
              this.getPversionPrice()
            } else {
              this.$message({
                message: '加入培育池成功',
                type: 'success'
              })
            }
            this.getDetail()
          }
        })
      }
    },

    changeBookList() {
      this.getFavoritesList('ele')
    },

    add_collect(val) {
      this.collectId = val
      this.getFavoritesList()
    },

    sureSubmitCollect(val) {
      this.add_collectApi(val)
    },

    //添加收藏
    add_collectApi(val = '') {
      const showSelectt = localStorage.getItem('isShowSelectBook') || 0
      let params = {
        companyUuids: this.$route.query.companyUuid,
        packageId: this.$route.query.packageId,
        favoriteId: val,
        isShowSelectBook: showSelectt
      }
      collection_add(params).then(res => {
        if (res.code == 60000) {
          Bus.$emit('dt-stop-loading')
          if (showSelectt) {
            ResetSystemConfig()
          }
          if (this.tipGetPhone) {
            this.getPversionPrice()
          } else {
            this.$message({
              message: '加入培育池成功',
              type: 'success'
            })
          }
          this.$nextTick(() => {
            if (this.$refs.selectBook) {
              this.$refs.selectBook.closeModel()
            }
          })
        }
      })
    },

    // 续费线索套餐
    goBuyMeal() {
      const params = {
        type: 's3'
      }
      getPversionPrice(params).then(res => {
        if (res.code === 60000) {
          this.$store.dispatch('changeUserBuyType', '1')
          this.$store.dispatch('changeUserBuy', true)
        }
      })
    },

    // 跳转新页面
    goBuyProduct() {
      const url = window.location.href
      const index = url.indexOf('#')
      const newUrl = url.substring(0, index + 1)
      window.open(newUrl + '/buypay')
      Bus.$emit('closeUserBuyMeal', false)
    },

    // 充值流量
    graspGoPay(val) {
      if (val === 'c') {
        this.showGraspC = false
        return
      }
      const params = {
        type: 's3'
      }
      getPversionPrice(params).then(res => {
        if (res.code === 60000) {
          this.showGrasp = false
          this.$store.dispatch('changeUserBuyType', '1')
          this.$store.dispatch('changeUserBuy', true)
        } else {
          this.showGrasp = false
        }
      })
    },

    // 获取剩余流量
    getKeyNum() {
      getKeyNum().then(res => {
        if (res.code === 60000) {
          this.accEarnNum = res.data
        }
      })
    },

    // 提示用户抓取是否收藏
    sureSubTipModel() {
      this.sureTip = true
      this.sureSubData = {
        dialogWidth: '450px',
        dialogTitle: '领取线索',
        dialogTop: '250px',
        showFoot: true,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.sureSubTip?.init()
      })
    },

    submitContBtn() {
      if (this.detail.sub == true) {
        this.getPversionPrice()
      } else {
        this.tipGetPhone = true
        this.add_collectApi()
      }
    },

    subimtCancle() {
      Bus.$emit('dt-stop-loading')
      this.getPversionPrice()
    },

    // 抓取数据
    getPversionPrice() {
      this.sureTip = false
      this.postConSum()
    },

    // 用户抓取数据
    async userGraspPhone() {
      const data = await ResetAuthUserMeal()
      if (data.hasBase) {
        this.sureBuyMeal()
      } else {
        this.$store.dispatch('setGoBuyMeal', true)
      }
    },

    // 判断是否购买
    sureBuyMeal() {
      if (this.detail.sub == true) {
        this.getPversionPrice()
      } else {
        if (!this.isShowAgain) {
          this.tipGetPhone = true
          this.add_collectApi()
        } else {
          this.sureSubTipModel()
        }
      }
    },

    // 消耗线索
    postConSum() {
      if (this.accEarnNum.mounth <= 0) {
        if (this.accEarnNum.permanent <= 0) {
          // 弹出充值框
          if (this.$store.getters.userInfo.isAdmin) {
            this.userGraspModel()
          } else {
            this.childUserGraspModel()
          }
          return
        }
      }
      const params = {
        companyUuid: this.$route.query.companyUuid,
        packageId: this.$route.query.packageId
      }
      postConSum(params).then(async res => {
        if (res.code === 60000) {
          this.getDetail('module')
          this.getKeyNum()
          this.getNetStatus()
        }
      })
    },

    childUserGraspModel() {
      this.showGraspC = true
      this.graspData = {
        dialogWidth: '500px',
        dialogTitle: '您的线索流量余额不足',
        dialogTop: '250px',
        showFoot: false,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.graspTipC.init()
      })
    },

    userGraspModel() {
      this.showGrasp = true
      this.graspData = {
        dialogWidth: '500px',
        dialogTitle: '您的线索流量余额不足',
        dialogTop: '250px',
        showFoot: false,
        singleBtn: false,
        dialogSurBtn: '确认'
      }
      this.$nextTick(() => {
        this.$refs.graspTip.init()
      })
    },

    toShop(url) {
      if (
        url.substr(0, 7).toLowerCase() == 'http://' ||
        url.substr(0, 8).toLowerCase() == 'https://'
      ) {
        url = url
      } else {
        url = 'http://' + url
      }
      window.open(url)
    },

    goBackPage() {
      this.$router.go(-1)
    },

    editRemark(val) {
      this.$store.dispatch('showModal', {
        title: '修改备注',
        size: '600px',
        view: addRemark,
        options: {
          remark: val,
          isUpdate: true,
          companyUuid: this.$route.query.companyUuid
        },
        onClose: res => {
          if (res.refresh) {
            this.getDetail()
          }
        }
      })
    },

    addRemark() {
      this.$store.dispatch('showModal', {
        title: '添加备注',
        size: '600px',
        view: addRemark,
        options: {
          companyUuid: this.$route.query.companyUuid,
          isUpdate: false
        },
        onClose: res => {
          if (res.refresh) {
            this.getDetail()
          }
        }
      })
    }
  },

  computed: {
    hxList() {
      const list = [
        {
          label: '项目名称',
          value: this.handleProjectName?.[0],
          project: true,
          show: this.handleProjectName?.length > 0,
          tip: this.handleProjectName?.length >= 2,
          count: this.handleProjectName.length
        },
        {
          label: '企业特征',
          show: this.handleHxQyTz,
          value: this.handleHxQyTz
        },
        {
          label: '属地落税',
          show: this.handleTax,
          value: this.handleTax
        },
        {
          label: '区域版块',
          block: true,
          show: this.handleBlock?.length > 0,
          value: this.handleBlock?.[0],
          count: this.handleBlock?.length,
          tip: this.handleBlock?.length >= 2
        },
        {
          label: '质量评级',
          value: this.handleLevel,
          show: this.handleLevel,
          score: `(${this.handleScore}分)`
        },
        {
          label: 'AI评分',
          show: this.detail.aiScore,
          value: this.detail.aiScore + '分',
          date: this.detail.aiScoreDate,
          txt: '报告下载',
          url: this.detail.aiReport
        }
      ]

      return list
    },

    handleHxQyTz() {
      return this.detail?.feature
    },

    isJump() {
      const perList = this.$store.state.permission.permission
      return perList.includes('系统管理-非菜单权限-企业详情页-跳转')
    },

    handleTax() {
      return this.detail?.localize
    },

    handleBlock() {
      return this.detail?.blocks
    },

    handleProjectName() {
      return this.detail?.projects
    },

    handleScore() {
      return this.detail?.qualityScore
    },

    handleLevel() {
      return this.detail?.qualityRating
    },

    isOperatePanel() {
      const perList = this.$store.state.permission.permission
      return perList.includes('系统管理-非菜单权限-企业详情页-右侧操作区')
    },

    handleFlexStyle() {
      return this.isOperatePanel ? 'width:60%' : 'width:100%'
    },

    textTool() {
      const permissionList = this.$store.state.permission.permission
      const list = [
        {
          content: '脉脉',
          show: permissionList.includes('智能获客-推荐线索-脉脉触达'),
          click: () => {
            this.jumpMaiMai(this.detail.company_name)
          },
          src: require('../../assets/maimai_logo.png'),
          class: 'click_icon'
        },
        {
          content: '华为云代理商验证',
          show: permissionList.includes(
            '客户管理CRM-客户公海-华为云代理商验证'
          ),
          click: () => {
            this.jumpHuaWei(this.detail.company_name)
          },
          src: require('../../assets/hwy.svg'),
          class: 'click_icon'
        },
        {
          content: '客户画像匹配',
          show: this.detail.match,
          click: () => {
            this.openCustomerPic()
          },
          src: require('../../assets/customer_icon.svg'),
          class: 'click_icon'
        }
      ]
      return list
    },

    // 企业族群分类
    isShowRisk() {
      if (this.detail.risk) {
        return Object.values(this.detail.risk).every(item => item == 0)
      }
      return true
    },

    showScroll() {
      const currentUser = this.$store.state.user
      return (
        !currentUser.plate ||
        (currentUser.plate && currentUser.plateVersion === 'j')
      )
    },

    isZs() {
      return this.$store.state.user.accountInfo.versionNum.indexOf('003') > -1
    },

    customerInfoMap() {
      return this.$store.state.user.customerInfoMap
    },

    getTags() {
      return this.detail.tags
    },

    isOverSea() {
      return (
        this.detail.tags?.['行业标签']?.some(
          item => item?.tagName == '企业出海'
        ) || false
      )
    },

    isLink() {
      return (
        this.detail.tags?.['行业标签']?.some(item => item?.tagName == '连锁') ||
        false
      )
    },

    showTransferClueBtn() {
      const admin = this.$store.getters.accountType == '1'
      if (admin && +this.$route.query.packageId) {
        return true
      }
      return false
    },

    permissionList() {
      return this.$store.state.permission.permission
    },

    moduleParamsList() {
      return this.$store.state.plugin.modulePackList
    },

    versionNum() {
      return this.$store.state.user.accountInfo.versionNum
    },

    //云行业开关
    isShowCloud() {
      return this.versionList.some(item => item.name == '云行业')
    },

    isHotShow() {
      const list = this.$store.state.user.accountInfo.cloudOpenList
      return list?.length == 1 && list[0].id != 0
    },

    versionList() {
      //过滤通用行业
      return this.$store.state.user.accountInfo.cloudOpenList.filter(
        item => item.id != 0
      )
    },

    //营收开关
    isShowComeIndex() {
      return this.$store.state.user.accountInfo.incomeIndexOpen
    },

    isShowAgain() {
      return this.$store.state.user.sysConfig.isShowSelectBook
    },

    isShowNetList() {
      return this.netList?.length > 0
    },

    //触达模块开关
    showTouch() {
      return this.$store.state.user.accountInfo.touchDisplay == 1
    },

    newTabData() {
      const initList = ['企业信息']
      let resList = this.versionList.map(item => item.name) //后台配置显示哪些tab

      //自定义显示具体的tab
      if (!this.isOverSea) {
        resList = resList.filter(item => item != '企业出海')
      }
      if (!this.isLink) {
        resList = resList.filter(item => item != '连锁行业')
      }
      console.log(resList, 'resList')
      return initList.concat(resList)
    },

    showHotDesc() {
      return this.$store.state.user.accountInfo.hotDisplay == 1
    },

    descList() {
      const list = [
        {
          name: '热度(年度)',
          key: 'hot',
          icon: 'rdd-icon',
          transfer: false,
          dateKey: 'hot-date',
          value: 0,
          show: this.isHotShow
        },
        {
          name: 'AI热度(年度)',
          key: 'aiHot',
          icon: 'ai',
          transfer: false,
          dateKey: 'ai-hot-date',
          value: 0,
          show: this.isHotShow
        },
        {
          name: '活跃度',
          key: 'activity_class',
          icon: 'hyd-icon',
          transfer: false,
          value: 0,
          show: this.isPrivate
        },
        {
          name: '云服务商',
          key: 'provider',
          icon: 'yy-icon',
          transfer: false,
          value: 0,
          show: this.isShowCloud,
          remain: '历史云服务商'
        },
        {
          name: 'CDN服务商',
          key: 'cdn',
          icon: 'cdnn-icon',
          transfer: false,
          value: 0,
          show: this.isShowCloud
        },
        {
          name: '邮件服务商',
          key: 'companyemail_mail_gongyingshang',
          icon: 'yjj-icon',
          transfer: false,
          value: 0,
          show: this.isShowCloud
        },
        {
          name: '营收指数(年度)',
          key: 'income_index',
          icon: 'open_in',
          transfer: false,
          dateKey: 'income_index_deadline',
          dateValue: 0,
          value: 0,
          show: this.isShowComeIndex != 0
        },
        {
          name: '研发指数(年度)',
          key: 'research_index',
          icon: 'yanfa',
          dateKey: 'research_index_deadline',
          dateValue: 0,
          transfer: false,
          value: 0,
          show: this.isShowComeIndex != 0
        },
        {
          name: '企业估值',
          key: 'valuation_index',
          icon: 'qy',
          dateKey: 'valuation_index_deadline',
          dateValue: 0,
          transfer: false,
          value: 0,
          show: this.isShowComeIndex != 0
        },
        {
          name: '门店数/分支机构数',
          key: 'branch_count',
          icon: 'store',
          dateKey: 'store_index_deadline',
          dateValue: 0,
          transfer: false,
          value: 0,
          show: this.isShowComeIndex != 0
        }
      ]
      return list
    }
  },

  watch: {
    '$store.state.settings.isOn': {
      handler(val) {
        if (val && !this.emitEvent) {
          //监听事件不能触发 注册isOn来触发领取事件
          this.getContactType()
        }
        console.log(this.emitEvent, 'emitReceiveEvent')
      },
      immediate: true,
      deep: true
    }
  },

  beforeDestroy() {
    Bus.$off('dataReady')
    this.$bus.$off('handleMoreCompany')
    this.emitEvent = false
  }
}
</script>
<style lang="scss">
@import '~@/styles/variables.scss';
.app-container {
  overflow: hidden;
  .company-item {
    display: flex;
    margin-top: 10px;
    font-size: 12px;
    color: #8a8a8a;
    font-weight: 400;
    span {
      &:first-child {
        width: 80px;
      }
      &:last-child {
        width: 90%;
      }
    }
    .website {
      color: #0077cc;
      cursor: pointer;
    }
  }
  .detail-wrap {
    background-color: #fff;
    font-size: 14px;
    padding: 10px;
    color: #8a8a8a;
    .key-title {
      color: $main-color;
      cursor: pointer;
    }
    span {
      &:nth-child(2) {
        margin: 0 8px;
      }
    }
  }
  .star {
    cursor: pointer;
    font-size: 25px;
    position: relative;
    vertical-align: top;
    top: -1px;
  }

  .new-qy-tag {
    .flex-tag {
      display: flex;
      margin-top: 10px;
      .tag-classify {
        font-size: 12px;
        color: #a8a8a8;
        position: relative;
        top: 8px;
      }
      .tag-row {
        display: flex;
        flex: 1;
        margin-left: 10px;
        flex-wrap: wrap;
        &:not(:first-child) {
          margin-top: 10px;
        }
        &:first-child {
          margin-top: -3px;
        }
      }
      .other-wrapper {
        .other-tag {
          margin: 4px;
          cursor: pointer;
        }
      }
    }
    .bandTag {
      width: 90%;
      margin-left: 10px;
      .other-wrapper {
        cursor: pointer;
      }
    }
    .new-tag {
      width: 90%;
      margin-left: 10px;
    }
  }

  .h-title {
    font-size: 12px;
    color: #a8a8a8;
  }

  .flex-hx {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    .flex-hx-item {
      margin: 5px;
      font-size: 12px;
      width: 240px;
      height: 60px;
      padding: 5px;
      border: 1px solid $main-color;
      line-height: 20px;
    }
    .h-it {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      color: $main-color;
      margin-top: 10px;
    }
    .date {
      margin: 0 10px;
    }
    .h-point {
      cursor: pointer;
      color: rgb(55, 54, 164);
    }
  }

  .key-remark {
    width: 620px;
    position: relative;
  }
  .click_icon {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
  .vip-click {
    display: flex;
    justify-content: center;
  }
  .company_desc {
    position: absolute;
    top: -10px;
    right: 0px;
    color: #aaa;
    font-size: 13px;
  }
  .desc-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 10px 0;
    & > div {
      padding: 5px;
      box-sizing: border-box;
      width: calc((100% - 40px) / 3);
      height: 50px;
      border: #32a7f0 solid 1px;
      border-radius: 3px;
      position: relative;
      display: flex;
      margin-bottom: 10px;
      margin-right: 10px;
      .left {
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        color: #32a7f0;
      }
      .right {
        width: calc(100% - 35px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5px;
        color: #8a8a8a;
        font-size: 12px;
        .cloud-add {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .remain {
            color: $main-color;
            cursor: pointer;
          }
        }
        & > div:last-child {
          margin-top: 3px;
          font-weight: bold;
          color: #333333;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .history-cloud {
      position: absolute;
      right: 4px;
      top: 4px;
      color: #1e98d7;
      font-size: 12px;
      cursor: pointer;
    }
    .detail-desc {
      font-weight: bold;
      font-size: 14px;
      margin-top: 4px;
      color: #000;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .index-desc {
        font-size: 12px;
        color: #777;
      }
    }
  }
  .company_brief {
    font-size: 14px;
    margin: 0 10px;
    background: rgb(242, 242, 242);
    padding: 2px 10px;
  }
  .vc {
    background: rgb(242, 242, 242);
    font-size: 14px;
    padding: 2px 10px;
  }
  .detail-comment {
    color: #2e2e2e;
    vertical-align: top;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 590px;
  }
  .el-btn-group {
    margin-top: 20px;
    .el-button {
      &:not(:first-child) {
        margin: 0 20px;
      }
    }
  }
  .net-wrap {
    background: #fff;
    position: relative;
    .net-table {
      margin-top: 0 !important;
    }
    .net-title {
      margin: 10px;
      font-size: 16px;
      display: inline-block;
      color: #2e2e2e;
    }
  }
  .map-title {
    background-color: #fff;
    font-size: 16px;
    padding: 10px;
    color: #2e2e2e;
  }
  .key-detail-risk {
    display: flex;
    align-items: center;
    background: #fff;
    font-size: 14px;
    border: 1px solid #dfdfdf;
    clear: both;
    margin-top: 30px;
    p {
      background: red;
      height: 40px;
      width: 50px;
      line-height: 40px;
      text-align: center;
      color: #fff;
    }
    ul {
      display: flex;
      align-items: center;
      width: 85%;
      li {
        width: 20%;
        text-align: center;
        list-style: none;
        background: #ffd6d6;
        margin: 0 20px;
        height: 30px;
        line-height: 30px;
        color: red;
      }
    }
  }

  .add-tab {
    display: flex;
    align-items: center;
  }
  .key-detail-company {
    margin-top: 10px;
  }
  .add-app {
    font-size: 12px !important;
    height: 60px;
    margin-bottom: 10px;
    display: flex;
    background-color: #dcf3f3;

    .name {
      width: 26px;
      line-height: 25px;
      background-color: #00aaaa;
      color: #fff;
      padding: 6px;
      box-sizing: border-box;
    }

    .btn {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & > div {
        padding: 0 10px;
        box-sizing: border-box;
        margin-left: 10px;
        background-color: #00aaaa;
        border-radius: 5px;
        color: #ffffff;
        user-select: none;
        cursor: pointer;
        height: 40px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
      }

      & > div:last-child {
        background-color: #fff;
        border: 1px solid #00aaaa;
        color: #00aaaa;
      }
    }
  }
}
</style>

<style scoped>
@import '../../styles/threadpx.scss';
@import '~@/styles/variables.scss';
.pySetting {
  display: flex;
  justify-content: flex-end;
  color: #1e98d7;
  cursor: pointer;
}
.pack-name-tip {
  cursor: pointer;
  font-size: 22px;
}
.hover-tip-icon {
  position: absolute;
  display: inline-block;
  width: 60px;
  text-align: center;
  height: 30px;
  border: 1px solid #979797;
  border-radius: 5px;
  left: 50px;
  top: -20px;
  color: #979797;
  line-height: 28px;
  font-size: 12px;
  background: white;
}
.show_phone_list {
  height: 128px;
  overflow-y: scroll;
}
.show_remark_list {
  display: inline-block;
  text-align: center;
  width: 100%;
}
.show_remark_cont ::v-deep .el-dialog .el-dialog__body {
  padding: 12px 15px;
}

.show_remark_name {
  width: 100%;
  display: inline-block;
  color: #8a8a8a;
  text-align: left;
  font-size: 12px;
  line-height: 17px;
}

.edit_remark_user {
  color: #0099cc;
  font-size: 12px;
  cursor: pointer;
  padding-top: 20px;
  text-align: center;
}

.show_remark_cont ::v-deep .el-dialog .el-dialog__header .el-dialog__headerbtn {
  display: none;
}

.show_remark_cont ::v-deep .el-dialog {
  border-radius: 0 !important;
  border: 1px solid $main-color;
  display: inline-block;
  margin-left: 480px;
  box-shadow: none;
}

.show_remark_cont ::v-deep .el-dialog__header {
  padding: 0 !important;
}

.show_phone_name {
  width: 32%;
  display: inline-block;
  color: $main-color;
  padding-top: 10px;
  text-align: center;
}

.show_com_cont ::v-deep .el-dialog {
  border-radius: 0 !important;
  border: 1px solid $main-color;
  display: inline-block;
  margin-left: 245px;
  box-shadow: none;
}

.show_com_cont ::v-deep .el-dialog__header {
  padding: 0 !important;
}

.trans-to-cus {
  height: 100px;
  line-height: 30px;
}

.show_grasp_text ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: $main-color;
  border-color: $main-color;
}

.show_grasp_text ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: $main-color;
}

.show_grasp_text ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: $main-color;
}

.detail-company {
  height: auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.jing-pin {
  position: fixed;
  left: 50%;
  top: 50%;
  height: 150px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  border-radius: 3px;
  box-sizing: border-box;
  color: red;
  font-size: 70px;
  opacity: 0.6;
  font-weight: bolder;
  transform: translate(-50%, -50%) rotate(-25deg);
  z-index: 9999;
}

.collect_remark_detail_new {
  cursor: pointer;
  color: #0099cc;
  font-size: 14px;
  margin-left: 2px;
}

.n-tip {
  color: #8a8a8a;
  font-size: 12px;
  margin-left: 5px;
  font-weight: 400;
}

.company-name ::v-deep .el-badge__content {
  background-color: $main-color !important;
}
.company-name {
  position: relative;
  display: flex;
  align-items: center;
}
.brief-desc {
  color: #aaa;
  margin-top: 10px;
  font-size: 14px;
}
</style>
